import React, { useEffect, useState } from 'react'
import { api, CreateMedia } from '../../data/api'
import styled from 'styled-components'
import { Modal, Button } from '@duckma/react-ds'
import { CreateTab } from './components/CreateTab'
import { useNetworkQueue } from '../../hooks/useQueue'

export const CreateMediaModal: React.FC<{
  open: boolean
  onClose?: () => void
}> = ({ open, onClose = () => {} }) => {
  const [loadQueue, { enqueue }] = useNetworkQueue(api.createMedia)
  const [toUpload, setToUpload] = useState<CreateMedia[]>([])
  const [confirmDisabled, setConfirmDisabled] = useState(true)
  const [uploadStarted, setUploadStarted] = useState(false)

  useEffect(() => {
    setConfirmDisabled(true)
    setToUpload([])
  }, [open])

  useEffect(() => {
    if (loadQueue.length === 0 && uploadStarted) {
      onClose()
    }
  }, [loadQueue, onClose, uploadStarted])

  return (
    <StyledModal open={open} onClose={onClose} title="Aggiungi Immagine">
      <Content>
        <CreateTab setConfirmDisabled={setConfirmDisabled} setToUpload={setToUpload} multi />
        <div style={{ width: '100%', display: 'flex', flexGrow: 0 }}>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Aggiungi a libreria media"
            radius={4}
            loading={loadQueue.length > 0}
            disabled={confirmDisabled}
            onClick={() => {
              toUpload.forEach(enqueue)
              setUploadStarted(true)
            }}
            style={{ flexGrow: 0, flexBasis: '30%', margin: '35px' }}
          />
        </div>
      </Content>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  position: fixed;
  top: 5vh;
  left: 10vw;
  right: 10vw;
  bottom: 5vh;
  outline: none;
  border-radius: 10px;
  > * {
    z-index: 2;
  }
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  height: calc(90vh - 64px);
  > * {
    width: calc(100% - 100px - 50px);
    border-radius: 10px;
  }
  > :first-child {
    flex-grow: 1;
  }
`

import React, { useEffect, useState, useContext, useRef } from 'react'
import { CreateMedia } from '../../../data/api'
import styled from 'styled-components'
import { Button, ThemeContext } from '@duckma/react-ds'
import { readFileAsync } from '../../../utils/helpers'
import { MediaCard } from './MediaCard'

export const CreateTab: React.FC<{
  setToUpload: (toUpload: CreateMedia[]) => void
  setConfirmDisabled: (disabled: boolean) => void
  multi: boolean
}> = ({ setToUpload, setConfirmDisabled, multi }) => {
  const theme = useContext(ThemeContext)
  const uploadRef = useRef<HTMLInputElement>(null)
  const [data, setData] = useState<{ image: string; title: string }[]>([])

  useEffect(() => {
    setConfirmDisabled(data.length === 0 || data.some(({ title }) => title.length === 0))
  }, [data, setConfirmDisabled])

  useEffect(() => {
    setToUpload(
      data.map((d) => ({
        base64: d.image,
        title: d.title,
        type: 'image',
      }))
    )
  }, [data, setToUpload])

  return (
    <TabContainer>
      {data.length > 0 ? (
        <MediaCardContainer>
          {data.map((image, i) => (
            <MediaCard
              key={i}
              imageUrl={image.image}
              title={image.title}
              onChange={(title) =>
                setData((data) => Object.assign([], data, { [i]: { ...data[i], title } }))
              }
              onDelete={() => setData((data) => [...data.slice(0, i), ...data.slice(i + 1)])}
            />
          ))}
        </MediaCardContainer>
      ) : (
        <UploadFileArea
          borderColor={theme['gray50']}
          onDrop={async (ev) => {
            ev.preventDefault()
            ev.stopPropagation()
            const files = Array.from(ev.dataTransfer.files)
            for (let f of files) {
              if (!f.type.startsWith('image/')) {
                return Promise.resolve()
              }
            }
            const newData = await Promise.all(
              files.map(async (file) => ({ image: await readFileAsync(file), title: file.name }))
            )
            setData(newData)
            if (uploadRef?.current) {
              uploadRef.current.value = ''
            }
          }}
          onDragEnd={(ev) => {
            ev.preventDefault()
            ev.stopPropagation()
          }}
          onDragOver={(ev) => {
            ev.preventDefault()
            ev.stopPropagation()
          }}
        >
          <>
            <input
              type="file"
              id="files"
              name="files[]"
              multiple={multi}
              accept="image/png, image/jpeg, image/gif"
              style={{ visibility: 'hidden' }}
              ref={uploadRef}
              onChange={async () => {
                const files = Array.from(uploadRef?.current?.files || [])
                for (let f of files) {
                  if (!f.type.startsWith('image/')) {
                    return Promise.resolve()
                  }
                }
                const newData = await Promise.all(
                  files.map(async (file) => ({
                    image: await readFileAsync(file),
                    title: file.name,
                  }))
                )
                setData(newData)
                if (uploadRef?.current) {
                  uploadRef.current.value = ''
                }
              }}
            />
            <UploadFileText color={theme['gray50']}>
              Trascina oppure scegli l'immagine
            </UploadFileText>
            <Button
              text="Scegli Immagine"
              outline
              style={{ width: '12vw' }}
              radius={4}
              onClick={(ev) => {
                ev.preventDefault()
                if (data.length === 0) {
                  uploadRef?.current?.click()
                }
              }}
            />
          </>
        </UploadFileArea>
      )}
    </TabContainer>
  )
}

const TabContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: scroll;
  max-height: 60vh;
  padding-left: 50px;
  padding-right: 50px;
  background-color: white;
`

const MediaCardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(375px, 1fr));
  grid-gap: 1rem;
  justify-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`

const UploadFileArea = styled.form<{ borderColor: string }>`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.borderColor};
  width: 100%;
  height: 45vh;
  margin-top: 40px;
  margin-bottom: 40px;
`

const UploadFileText = styled.div<{ color: string }>`
  margin-bottom: 30px;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: 800;
  color: ${(props) => props.color};
`

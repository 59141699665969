import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button, SectionTitle, Label } from '@duckma/react-ds'

import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import moment from 'moment'

export const SystemPage = () => {
  const [request, { status, data }] = useRequest(api.getPWAVersion)
  const [update, { status: updateStatus, data: updateData }] = useRequest(api.updatePWAVersion)

  useEffect(() => {
    if (status === 'idle') {
      request({})
    }
  }, [request, status])

  useEffect(() => {
    if (updateStatus === 'success' && updateData) {
      request({})
    }
  }, [updateStatus, updateData, request])

  useSuccessToast(updateStatus, 'Le app si aggiorneranno a breve.')

  return (
    <Container>
      <SectionTitle text="Aggiornamento PWA" />
      <div style={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
        <Label style={{ flexGrow: 1, width: '100%' }}>
          Ultimo aggiornamento forzato: {data?.version ? moment(data.version).format('LLLL') : ''}
        </Label>
        <Button
          text="Forza aggiornamento"
          radius={4}
          disabled={status === 'loading'}
          loading={updateStatus === 'loading'}
          onClick={() => {
            update({})
          }}
          style={{ flexGrow: 0, maxWidth: '200px' }}
        />
      </div>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

// const SectionDivider = styled(Divider)`
//   margin: 30px -54px;
// `

import React from 'react'
import { ThemeContext } from '@duckma/react-ds'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { theme } from './styles/theme'
import { LoginPage } from './modules/auth/Login'
import './styles/global.css'
import { storage } from './data/storage'
import { UsersPage } from './modules/users/Users'
import { PasswordReset } from './modules/auth/PasswordReset'
import { PasswordOTP } from './modules/auth/PasswordOTP'
import { UpdatePassword } from './modules/auth/UpdatePassword'
import { MainLayout } from './components/MainLayout'
import { ProfilePage } from './modules/profile/Profile'
import { CreateUser } from './modules/users/CreateUser'
import { UserDetail } from './modules/users/UserDetail'
import { Role } from './data/models'
import { MediaPage } from './modules/media/Media'
import { ItemsPage } from './modules/items/Items'
import { ItemDetail } from './modules/items/ItemDetail'
import { ProspectDetail } from './modules/prospects/ProspectDetail'
import { ProspectsPage } from './modules/prospects/Prospects'
import { SystemPage } from './modules/system/System'

const PrivateRoute = ({
  component: Component,
  componentProps,
  header,
  allowRoles,
  ...rest
}: {
  component: React.ComponentType<any>
  componentProps?: {}
  header: React.ComponentProps<typeof MainLayout>
  allowRoles?: Role[]
  [key: string]: any
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        storage.getToken() !== null &&
        (allowRoles === undefined || allowRoles.includes(storage.getUser().role)) ? (
          <MainLayout {...header}>
            <Component {...props} {...componentProps} />
          </MainLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

function App() {
  return (
    <ThemeContext.Provider value={theme}>
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/password-reset">
            <PasswordReset />
          </Route>
          <Route path="/password-otp">
            <PasswordOTP />
          </Route>
          <Route path="/update-password">
            <UpdatePassword />
          </Route>
          <Route exact path="/">
            <Redirect to="/users" />
          </Route>
          <PrivateRoute
            path="/users/create"
            component={CreateUser}
            allowRoles={['admin', 'seller']}
            header={{
              title: 'Aggiungi nuovo utente',
              backLink: {
                text: 'Torna alla lista utenti',
                to: '/users',
              },
            }}
          />
          <PrivateRoute
            path="/users/:id"
            component={UserDetail}
            header={{
              title: 'Dettaglio Utente',
              backLink: {
                text: 'Torna alla lista utenti',
                to: '/users',
              },
            }}
          />
          <PrivateRoute
            path="/users"
            component={UsersPage}
            header={{
              title: 'Utenti',
              headerButton: {
                text: 'Aggiungi utente',
                to: '/users/create',
                // roles: ['admin'],
              },
            }}
          />
          <PrivateRoute
            path="/items/create"
            component={ItemDetail}
            allowRoles={['admin']}
            header={{
              title: 'Nuova Voce',
              backLink: {
                text: 'Torna alla lista voci',
                to: '/items',
              },
            }}
          />
          <PrivateRoute
            path="/items/:id"
            component={ItemDetail}
            allowRoles={['admin']}
            header={{
              title: 'Dettaglio Voce',
              backLink: {
                text: 'Torna alla lista voci',
                to: '/items',
              },
            }}
          />
          <PrivateRoute
            path="/items"
            component={ItemsPage}
            allowRoles={['admin']}
            header={{
              title: 'Voci',
              headerButton: {
                text: 'Aggiungi voce',
                to: '/items/create',
                roles: ['admin'],
              },
            }}
          />
          <PrivateRoute
            path="/prospects/saved"
            component={ProspectsPage}
            header={{
              title: 'Offerte salvate',
            }}
            componentProps={{ status: 'saved' }}
          />
          <PrivateRoute
            path="/prospects/requested"
            component={ProspectsPage}
            header={{
              title: 'Offerte Richieste',
            }}
            componentProps={{ status: 'requested' }}
          />
          <PrivateRoute
            path="/prospects/accepted"
            component={ProspectsPage}
            header={{
              title: 'Offerte Accettate',
            }}
            componentProps={{ status: 'accepted' }}
          />
          <PrivateRoute
            path="/prospects/:id"
            component={ProspectDetail}
            header={{
              title: 'Dettaglio Richiesta',
              backLink: {
                text: 'Torna alla lista richieste',
                to: '/prospects/requested',
              },
            }}
          />
          <PrivateRoute
            path="/media/create"
            component={MediaPage}
            allowRoles={['admin']}
            header={{
              title: 'Libreria Media',
              headerButton: {
                text: 'Nuovo Media',
                to: '/media/create',
              },
            }}
          />
          <PrivateRoute
            path="/media/:id"
            component={MediaPage}
            allowRoles={['admin']}
            header={{
              title: 'Libreria Media',
              headerButton: {
                text: 'Nuovo Media',
                to: '/media/create',
              },
            }}
          />
          <PrivateRoute
            path="/media"
            component={MediaPage}
            allowRoles={['admin']}
            header={{
              title: 'Libreria Media',
              headerButton: {
                text: 'Nuovo Media',
                to: '/media/create',
              },
            }}
          />
          <PrivateRoute
            path="/me"
            component={ProfilePage}
            header={{
              title: 'Profilo',
            }}
          />
          <PrivateRoute
            path="/system"
            component={SystemPage}
            header={{ title: 'Configurazione di sistema' }}
          />
        </Switch>
      </Router>
    </ThemeContext.Provider>
  )
}

export default App

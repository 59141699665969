import { useMemo } from 'react'
import _ from 'lodash'

import { Category, PriceRecurrence, PriceModel, ProspectItem, Item } from '../data/models'
import { priceRounder } from '../utils/formatters'

export const priceWithPriceList = (item: Item | ProspectItem, multiplierPercent: number = 100) => {
  let itemPrice =
    item.price_model === PriceModel.FIXED ? item.price * (multiplierPercent / 100) : item.price
  if (item.price_recurrence === PriceRecurrence.MONTHLY) {
    if (item.price_model === PriceModel.FIXED) {
      itemPrice /= 12
    } else {
      itemPrice = (itemPrice - 100) / 12 + 100
    }
  }
  if (item.price_model === PriceModel.FIXED) {
    // Roundup to nearest 5
    itemPrice = priceRounder(itemPrice)
  }
  return itemPrice
}

const calculateTotalPrices = (
  items: ProspectItem[],
  multiplierPercent?: number,
  stopToCategory?: Category
): TotalPrices => {
  if (!items || items.length === 0)
    return { hasCustomizations: false, oneShotTotal: 0, recurrentTotal: 0, byCategory: {} }

  if (stopToCategory) {
    // Do not calculate pricing for all categories above stopToCategory
    const stopIndex = Object.keys(Category).findIndex((v) => v === stopToCategory)
    const bannedCategories = Object.keys(Category).filter((c, i) => i > stopIndex)
    items = items.filter((i) => !bannedCategories.includes(i.category))
  }
  const hasCustomizations = items.some((item) => item.customization)
  const itemsByCategory = _(items)
    .map((i) => {
      return {
        ...i,
        price: priceWithPriceList(i, multiplierPercent),
      }
    })
    .groupBy('category')
    .value()

  const totals = (['components', 'graphics', 'platforms', 'services'] as Category[]).reduce<
    Omit<TotalPrices, 'hasCustomizations'>
  >(
    (acc, c) => {
      const [oneShot, recurrent] = _.partition(
        itemsByCategory[c] || [],
        (i) => i.price_recurrence === PriceRecurrence.ONESHOT
      )
      const [oneShotFixed, oneShotPercentage] = _.partition(
        oneShot,
        (i) => i.price_model === PriceModel.FIXED
      )
      const [recurrentFixed, recurrentPercentage] = _.partition(
        recurrent,
        (i) => i.price_model === PriceModel.FIXED
      )

      const oneShotTotal =
        acc.oneShotTotal +
        _.sumBy(oneShotFixed, 'price') +
        _.sumBy(oneShotPercentage, (i) => (i.price / 100 - 1) * acc.oneShotTotal)
      const recurrentTotal =
        acc.recurrentTotal +
        _.sumBy(recurrentFixed, 'price') +
        _.sumBy(recurrentPercentage, (i) => (i.price / 100 - 1) * acc.oneShotTotal)

      return {
        oneShotTotal,
        recurrentTotal,
        byCategory: {
          ...acc.byCategory,
          [c]: {
            oneShotTotal,
            recurrentTotal,
          },
        },
      }
    },
    { oneShotTotal: 0, recurrentTotal: 0, byCategory: {} }
  )

  return {
    ...totals,
    hasCustomizations,
  }
}

export const useTotalPrices = (
  items: ProspectItem[],
  multiplierPercent?: number,
  stopToCategory?: Category
) =>
  useMemo(() => calculateTotalPrices(items, multiplierPercent, stopToCategory), [
    items,
    multiplierPercent,
    stopToCategory,
  ])

export type TotalPrices = {
  hasCustomizations: boolean
  oneShotTotal: number
  recurrentTotal: number
  byCategory: {
    [key: string]: {
      oneShotTotal: number
      recurrentTotal: number
    }
  }
}

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { SectionTitle } from '@duckma/react-ds'

import { Category } from '../../../data/models'
import { Select } from '../../../components/Select'

type Props = {
  initialData?: Category
  onChange: (category: Category) => void
}

export const CategorySection: React.FC<Props> = ({ initialData, onChange }) => {
  const [category, setCategory] = useState<{ value: Category; label: string } | null>(null)

  useEffect(() => {
    setCategory(
      initialData
        ? {
            value: initialData,
            label: initialData,
          }
        : null
    )
  }, [initialData])

  useEffect(() => {
    if (category) {
      onChange(category.value)
    }
  }, [onChange, category])

  return (
    <>
      <SectionTitle text="Categoria" />
      <Container>
        <StyledSelect
          name="categoria"
          placeholder="Seleziona la categoria"
          label="Categoria *"
          getItemsPromise={(search: string) =>
            Promise.resolve(
              Object.values(Category)
                .filter((c) => c.toLowerCase().includes(search.toLowerCase()))
                .map((c) => ({ value: c, label: c }))
            )
          }
          value={category}
          onChange={(selected) => setCategory(selected as { value: Category; label: string })}
        />
        <div />
      </Container>
    </>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10%;
  padding-top: 30px;
`

const StyledSelect = styled(Select)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 13px;
  width: 50%;
  > div {
    border: 0.5px solid #828894;
    border-radius: 2px;
  }
`

import React from 'react'
import styled from 'styled-components'
import { Typography } from '@duckma/react-ds'

import { ProspectItem, PriceModel, PriceRecurrence, Category } from '../../../data/models'
import { priceRounder } from '../../../utils/formatters'
import { TotalPrices, priceWithPriceList } from '../../../hooks/useTotalPrices'

export const categories = Object.values(Category)

const getPreviousCategory = (category: Category) =>
  categories[Math.max(categories.findIndex((c) => c === category) - 1, 0)]

export const FormattedPrice: React.FC<{
  item: ProspectItem
  multiplierPercent?: number
  totalPrices: TotalPrices
}> = ({ item, multiplierPercent, totalPrices }) => {
  const lastCategoryTotal = totalPrices.byCategory[getPreviousCategory(item.category)] ?? {
    oneShotTotal: 0,
    recurrentTotal: 0,
  }
  const lastTotal = lastCategoryTotal.oneShotTotal

  const value =
    item.price_model === PriceModel.FIXED
      ? priceWithPriceList(item, multiplierPercent)
      : (priceWithPriceList(item, multiplierPercent) / 100 - 1) * lastTotal

  return (
    <PriceStyles>
      <span style={{ fontSize: '1em', fontWeight: 400 }}>{priceRounder(value) + ' €'}</span>
      {item.price_recurrence === PriceRecurrence.MONTHLY && '/ mese'}
    </PriceStyles>
  )
}

export const PriceStyles = styled(Typography)<{ alignRight: boolean }>`
  font-size: 1.1em;
  @media screen and (max-width: 800px) {
    font-size: 0.9em;
  }
  font-weight: 600;
  white-space: nowrap;
`

import React, { useState, useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { SectionTitle, Subtitle, Table, Ordering, Paginator, Column } from '@duckma/react-ds'

import { User } from '../../../data/models'
import { SkeletonTable } from '../../../components/SkeletonTable'
import { useRequest } from '../../../hooks/useRequest'
import { api } from '../../../data/api'

type Props = {
  user: User
}

export const UsersSection: React.FC<Props> = ({ user: { id, role } }) => {
  const [getUsers, { status, data }] = useRequest(api.getUsers)

  const [ordering, setOrdering] = useState<Ordering<User>>({
    key: 'first_name',
    direction: 'asc',
  })
  const [page, setPage] = useState<number>(0)

  const getUsersWithParams = useCallback(
    () =>
      getUsers({
        order_by: ordering.key,
        order_direction: ordering.direction,
        page,
        page_size: 5,
        role: 'user',
        seller: role === 'seller' ? id : undefined,
      }),
    [getUsers, ordering, page, role, id]
  )

  useEffect(() => {
    getUsersWithParams()
  }, [getUsersWithParams])

  const columns = useMemo(
    () =>
      [
        { key: 'first_name', name: 'Nome', orderable: true },
        { key: 'last_name', name: 'Cognome', orderable: true },
        {
          key: 'email',
          name: 'Email',
          orderable: true,
          render: (user) => <a href={`/users/${user.id}`}>{user.email}</a>,
        },
        {
          key: 'actions',
          name: 'Azioni',
          orderable: false,
        },
      ] as Column<User>[],
    []
  )

  return (
    <Section>
      <SectionTitle text={`Lista Utenti`} />
      <Container>
        {status !== 'loading' ? (
          <Table
            columns={columns}
            records={data ? data.items : []}
            order={ordering}
            onOrder={setOrdering}
          />
        ) : (
          <SkeletonTable />
        )}
        {status === 'success' && data && data.items.length > 0 && (
          <Paginator
            style={{ marginTop: '75px', marginBottom: '75px' }}
            currentPage={page}
            totalPages={data.pagination.total_pages}
            onPageSelect={setPage}
          />
        )}
        {status === 'success' && data && data.items.length === 0 && (
          <Subtitle text={`Nessun utente associato`} color="gray100" />
        )}
      </Container>
    </Section>
  )
}

const Section = styled.div`
  padding: 16px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
`

import React, { useContext } from 'react'
import styled from 'styled-components'
import { Input, ThemeContext, Button } from '@duckma/react-ds'
import { Image } from '../../../components/Image'

type Props = {
  imageUrl: string
  imageTitle?: string
  sizeBytes?: number
  fieldName?: string
  title: string
  onChange: (title: string) => void
  onDelete?: () => void
}

export const MediaCard = ({
  imageUrl,
  imageTitle = '',
  sizeBytes,
  fieldName = 'titolo',
  title,
  onChange,
  onDelete,
}: Props) => {
  const theme = useContext(ThemeContext)

  return (
    <Card backgroundColor={theme['gray50']}>
      {onDelete && (
        <Button
          size="icon"
          iconLeft="trash"
          color="danger"
          radius={24}
          style={{ alignSelf: 'flex-end', marginBottom: '-5px' }}
          onClick={(ev) => {
            ev.preventDefault()
            onDelete()
          }}
        />
      )}
      <PaddedInput
        name={fieldName}
        value={title}
        onChange={onChange}
        valid={title.length > 0}
        errorText="Il campo è obbligatorio"
      />
      <Image url={imageUrl} title={imageTitle} sizeBytes={sizeBytes} width="335px" height="215px" />
    </Card>
  )
}

type StyledProps = { backgroundColor: string }
const Card = styled.div<StyledProps>`
  border-radius: 10px;
  box-shadow: 0 2px 34px 0 rgba(130, 136, 148, 0.21);
  background-color: ${(props) => props.backgroundColor};
  padding-top: 20px;
  padding-bottom: 24px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 20px;
  margin-left: 20px;
  width: 335px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
`

const PaddedInput = styled(Input)`
  padding-bottom: ${(props) => (props.valid ? '32px' : '0')};
`

import React, { useEffect, useState, useCallback } from 'react'
import { api } from '../../data/api'
import styled from 'styled-components'
import { Paginator, Subtitle } from '@duckma/react-ds'
import { useRequest } from '../../hooks/useRequest'
import { toast } from 'react-toastify'
import { SearchField } from '../../components/SearchField'
import { SkeletonTable } from '../../components/SkeletonTable'
import { Image } from '../../components/Image'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { CreateMediaModal } from './CreateMediaModal'
import { UpdateMediaModal } from './UpdateMediaModal'

export const MediaPage = () => {
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams()

  const createMode = location.pathname.includes('create')
  const updateMode = id !== undefined

  const [getMedias, { status, data }] = useRequest(api.getMedias)
  const [deleteMedia, { data: deleteData }, resetDelete] = useRequest(api.deleteMedia)

  const [search, setSearch] = useState<string | undefined>(undefined)
  const [page, setPage] = useState<number>(0)

  const getMediasWithParams = useCallback(
    () =>
      getMedias({
        order_by: 'created_at',
        order_direction: 'desc',
        search_text: search,
        page,
        page_size: 12,
      }),
    [getMedias, search, page]
  )

  useEffect(() => {
    getMediasWithParams()
  }, [getMediasWithParams, createMode, id])

  useEffect(() => {
    if (deleteData != null) {
      toast('Media eliminato con successo dalla libreria.', { type: 'success' })
      getMediasWithParams()
      resetDelete()
    }
  }, [deleteData, resetDelete, getMediasWithParams])

  document.body.style.overflow = createMode ? 'hidden' : 'unset'

  return (
    <Container>
      {createMode ? (
        <CreateMediaModal open onClose={() => history.push('/media')} />
      ) : updateMode && id ? (
        <UpdateMediaModal mediaId={id} />
      ) : (
        <>
          <SearchField
            style={{ alignSelf: 'flex-end', marginBottom: '35px' }}
            onSearch={(search) => {
              if (page !== 0) {
                setPage(0)
              }
              setSearch(search)
            }}
          />
          {status !== 'loading' ? (
            <ImageContainer>
              {data?.items.map((m) => (
                <Image
                  key={m.id}
                  url={m.thumbnail_url || m.url}
                  title={m.title}
                  sizeBytes={m.size_bytes}
                  width="300px"
                  height="200px"
                  onEdit={() => {
                    history.push(`/media/${m.id}`)
                  }}
                  onDelete={() => {
                    if (
                      window.confirm('Sei sicuro di voler eliminare questo media dalla libreria?')
                    ) {
                      deleteMedia({ id: m.id })
                    }
                  }}
                />
              ))}
            </ImageContainer>
          ) : (
            <SkeletonTable />
          )}
          {status === 'success' && data && data.items.length > 0 && (
            <Paginator
              style={{ marginTop: '75px', marginBottom: '75px' }}
              currentPage={page}
              totalPages={data.pagination.total_pages}
              onPageSelect={setPage}
            />
          )}
          {status === 'success' && data && data.items.length === 0 && (
            <Subtitle text="Nessun media trovato" />
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`

const ImageContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1rem;
  justify-items: center;
`

import React from 'react'
import styled from 'styled-components'
import { Button } from '@duckma/react-ds'

type Props = {
  id: string
  editButton: [(id: string) => void]
  deleteButton: [(id: string) => void, string, string | undefined]
  blockButton?: [
    'pending' | 'active' | 'blocked',
    [(id: string) => void, string],
    [(id: string) => void, string]
  ]
}
export const TableActions = ({
  id,
  editButton: [onEdit],
  deleteButton: [onDelete, confirmDeleteMessage, disabledMessage],
  blockButton,
}: Props) => {
  const [status, blockAction, activateAction] = blockButton || ['pending', [], []]
  return (
    <Actions>
      <Button
        size="icon"
        iconLeft="pen-alt"
        tooltip="Modifica"
        color="primary"
        radius={24}
        onClick={() => onEdit(id)}
      />
      <Button
        size="icon"
        iconLeft="trash"
        color="danger"
        tooltip={disabledMessage || 'Elimina'}
        radius={24}
        onClick={() => {
          if (!onDelete || (confirmDeleteMessage && !window.confirm(confirmDeleteMessage))) {
            return
          }
          onDelete(id)
        }}
        disabled={disabledMessage !== undefined}
      />
      {blockButton && status !== 'pending' && (
        <Button
          size="icon"
          iconLeft={status === 'active' ? 'stop' : 'refresh'}
          color={status === 'active' ? 'gray' : 'primary'}
          radius={24}
          tooltip={status === 'active' ? 'Blocca' : 'Riattiva'}
          onClick={() => {
            const [onAction, confirmMessage] = status === 'active' ? blockAction : activateAction
            if (!onAction || (confirmMessage && !window.confirm(confirmMessage))) {
              return
            }
            onAction(id)
          }}
        />
      )}
    </Actions>
  )
}

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  * {
    margin-right: 20px;
  }
`

import React from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer, toast } from 'react-toastify'
import * as serviceWorker from './serviceWorker'
import 'react-toastify/dist/ReactToastify.css'

import App from './App'

ReactDOM.render(
  <React.StrictMode>
    <App />
    <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} pauseOnFocusLoss={false} />
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.register()

import { Theme } from '@duckma/react-ds'

// export const theme: Theme = {
//   black: '#222222',
//   white: '#ffffff',
//   primary100: '#721843',
//   primary50: '#541232',
//   primary20: '#320B1E',
//   secondary100: '#1F2026',
//   secondary50: '#373943',
//   secondary20: '#494C5A',
//   tertiary100: '#ff3796',
//   tertiary50: '#ff9ccb',
//   tertiary20: '#ffd9eb',
//   gray100: '#828894',
//   gray50: '#e5e7eb',
//   gray20: '#f7f7f7',
//   success100: '#a1dd70',
//   success50: '#d0edb8',
//   success20: '#e8f6dd',
//   warning100: '#fdd043',
//   warning50: '#fee69a',
//   warning20: '#fff6da',
//   danger100: '#ff5959',
//   danger50: '#feaeae',
//   danger20: '#fedcdc',
// }

export const theme: Theme = {
  black: '#222222',
  white: '#ffffff',
  primary100: '#4d4e51',
  primary50: '#abb1be',
  primary20: '#d2f7e8',
  secondary100: '#302387',
  secondary50: '#968fc1',
  secondary20: '#d7d4ea',
  tertiary100: '#ff3796',
  tertiary50: '#ff9ccb',
  tertiary20: '#ffd9eb',
  gray100: '#828894',
  gray50: '#e5e7eb',
  gray20: '#f7f7f7',
  success100: '#a1dd70',
  success50: '#d0edb8',
  success20: '#e8f6dd',
  warning100: '#fdd043',
  warning50: '#fee69a',
  warning20: '#fff6da',
  danger100: '#ff5959',
  danger50: '#feaeae',
  danger20: '#fedcdc',
}

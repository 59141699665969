export const readFileAsync = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result as string)
    }

    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}

export const humanReadableBytes = (bytes: number) => {
  const sizes = ['B', 'kB', 'MB', 'GB', 'TB']

  for (const [i, size] of Array.from(sizes.entries())) {
    const thresholdK = 1000 ** i
    if (bytes < thresholdK * 1000) {
      return `${(bytes / thresholdK).toFixed(1)} ${size}`
    }
  }
  return '-'
}

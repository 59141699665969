import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { SectionTitle, Button, Description } from '@duckma/react-ds'

import { useMountEffect } from '../../../hooks/useMountEffect'
import { MediaSelector } from '../../media/MediaSelector'
import { Media } from '../../../data/models'
import { MediaCard } from '../../media/components/MediaCard'

type Props = {
  initialValues: Media[]
  onChange: (images: string[]) => void
}

export const ImagesSection: React.FC<Props> = ({ initialValues, onChange }) => {
  const [images, setImages] = useState<Media[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  // useDirty(images, initialValues)
  useMountEffect(() => {
    setImages(initialValues)
  })

  useEffect(() => {
    onChange(images.map((image) => image.id))
  }, [onChange, images])

  const onClose = useCallback(() => setModalOpen(false), [])

  return (
    <>
      <MediaSelector
        open={modalOpen}
        onClose={onClose}
        onSelect={(media) => setImages([...media])}
        hideIds={images.map((image) => image.id)}
      />
      <SectionTitle text="Immagini" style={{ marginTop: '30px', marginBottom: '20px' }} />
      {images.length === 0 && (
        <Description
          text="E' obbligatoria almeno un'immagine"
          color="danger100"
          style={{ marginBottom: '20px' }}
        />
      )}
      <Description
        text="I formati supportati sono JPEG, PNG e GIF"
        style={{ marginBottom: '30px' }}
      />
      <Container>
        {images.map((image, i) => (
          <MediaCard
            title="Immagine Componente"
            key={image.id}
            imageUrl={image.thumbnail_url || image.url}
            fieldName="didascalia"
            onChange={() => {
              /* NO CAPTION UPDATE */
            }}
            imageTitle={image.title}
            sizeBytes={image.size_bytes}
            onDelete={() => setImages(images.filter((_image) => _image.id !== image.id))}
          />
        ))}
      </Container>
      <Button
        text="Scegli Immagine"
        iconLeft="reflex"
        onClick={(ev) => {
          ev.preventDefault()
          setModalOpen(true)
        }}
        radius={4}
        style={{ width: '250px', marginBottom: '30px' }}
      />
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: -20px;
  margin-left: -20px;
  > * {
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px;
  }
`

/**
 * This file defines various form validator of general use.
 * Single use functions shouldn't be put here.
 *
 * Each export is a higher order function that takes various configurations as input,
 * and returns a ready-to-use validator.
 */
export type Validator<T = string> = (
  value: T,
  fields: { [fieldName: string]: any }
) => string | null

export const requiredValidator: (msg?: string) => Validator<string> = (
  msg = 'Il campo è obbligatorio'
) => lengthValidator(1, undefined, msg)

export const lengthValidator: (
  min: number,
  max?: number,
  minMsg?: string,
  maxMsg?: string
) => Validator<string> = (
  min,
  max = Number.MAX_VALUE,
  minMsg = `La lunghezza minima è di ${min} caratteri`,
  maxMsg = `La lunghezza massima è di ${max} caratteri`
) => (s) => (s.length < min ? minMsg : s.length > max ? maxMsg : null)

// Checks that 2 fields (current and another one) are equal.
export const equalFieldsValidator: (otherName: string, msg?: string) => Validator<string> = (
  otherName,
  msg = 'I due campi non corrispondono'
) => (s, fields) => (s !== fields[otherName] ? msg : null)

var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const emailValidator: (msg?: string) => Validator<string> = (msg = 'Email non valida') => (
  email
) => (emailRegex.test(email.toLowerCase()) ? null : msg)

export const requiredHtmlValidator: (msg?: string) => Validator<string> = (
  msg = 'Campo obbligatorio'
) => (description) => (description.replace(/<(.|\n)*?>/g, '').trim().length === 0 ? msg : null)

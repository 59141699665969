import React, { useEffect, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import {
  Table,
  Ordering,
  Tag,
  ColorName,
  Paginator,
  Column,
  Subtitle,
  useTheme,
} from '@duckma/react-ds'
import { toast } from 'react-toastify'
import 'microtip/microtip.css'

import { api } from '../../data/api'
import { useRequest } from '../../hooks/useRequest'
import { User, Role } from '../../data/models'
import { SearchField } from '../../components/SearchField'
import { SkeletonTable } from '../../components/SkeletonTable'
import { TableActions } from '../../components/TableActions'
import { dateTimeFormatter } from '../../utils/formatters'

const tags: { [key in Role]: { color: ColorName; label: string } } = {
  user: { color: 'primary100', label: 'Cliente' },
  seller: { color: 'gray100', label: 'Agenzia' },
  admin: { color: 'warning100', label: 'Admin' },
  super_admin: { color: 'danger100', label: 'Super Admin' },
}

export const UsersPage = () => {
  const history = useHistory()
  const [getUsers, { status, data }] = useRequest(api.getUsers)
  const [deleteUser, { data: deleteData }, resetDelete] = useRequest(api.deleteUser)
  const [blockUser, { data: blockData }, resetBlock] = useRequest(api.blockUser)
  const [unblockUser, { data: unBlockData }, resetUnBlock] = useRequest(api.unblockUser)
  const theme = useTheme()

  const [ordering, setOrdering] = useState<Ordering<User>>({
    key: 'first_name',
    direction: 'asc',
  })
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [page, setPage] = useState<number>(0)

  const getUsersWithParams = useCallback(
    () =>
      getUsers({
        order_by: ordering.key,
        order_direction: ordering.direction,
        search_text: search,
        page,
        page_size: 10,
      }),
    [getUsers, ordering, page, search]
  )

  useEffect(() => {
    getUsersWithParams()
  }, [getUsersWithParams])

  useEffect(() => {
    if (deleteData != null) {
      toast('Utente eliminato con successo', { type: 'success' })
      getUsersWithParams()
      resetDelete()
    }
  }, [deleteData, resetDelete, getUsersWithParams])

  useEffect(() => {
    if (blockData != null) {
      toast('Utente bloccato con successo', { type: 'success' })
      getUsersWithParams()
      resetBlock()
    }
  }, [blockData, resetBlock, getUsersWithParams])

  useEffect(() => {
    if (unBlockData != null) {
      toast('Utente riattivato con successo', { type: 'success' })
      getUsersWithParams()
      resetUnBlock()
    }
  }, [unBlockData, resetUnBlock, getUsersWithParams])

  const columns = useMemo(
    () =>
      [
        { key: 'first_name', name: 'Nome', orderable: true },
        { key: 'last_name', name: 'Cognome', orderable: true },
        {
          key: 'role',
          name: 'Ruolo',
          orderable: true,
          render: (user: User) => (
            <Tag text={tags[user.role].label} color={tags[user.role].color} />
          ),
        },
        { key: 'email', name: 'Email', orderable: true },
        {
          key: 'created_at',
          name: 'Data Creazione',
          orderable: true,
          render: ({ created_at }) => dateTimeFormatter(created_at),
        },
        {
          key: 'last_login',
          name: 'Ultimo Accesso',
          orderable: true,
          render: ({ last_login }) => (last_login ? dateTimeFormatter(last_login) : 'Nessuno'),
        },
        {
          key: 'status',
          name: 'Stato',
          orderable: true,
          render: (user) => (
            <StatusDot
              aria-label={
                user.status === 'active'
                  ? 'Attivo'
                  : user.status === 'blocked'
                  ? 'Bloccato'
                  : 'In attesa di conferma'
              }
              data-microtip-position="top"
              role="tooltip"
              color={
                user.status === 'active'
                  ? theme['success100']
                  : user.status === 'blocked'
                  ? theme['danger100']
                  : theme['warning100']
              }
            />
          ),
        },
        {
          key: 'actions',
          name: 'Azioni',
          orderable: false,
          render: (user: User) => (
            <TableActions
              id={user.id}
              editButton={[(id) => history.push(`/users/${id}`)]}
              deleteButton={[
                (id) => deleteUser({ id }),
                `Sei sicuro di voler cancellare l'utente (${user.email})? L'azione è irreversibile.`,
                undefined,
              ]}
              blockButton={[
                user.status,
                [
                  (id) => blockUser({ id }),
                  `Sei sicuro di voler bloccare l'utente (${user.email})?`,
                ],
                [
                  (id) => unblockUser({ id }),
                  `Sei sicuro di voler riattivare l'utente (${user.email})?`,
                ],
              ]}
            />
          ),
        },
      ] as Column<User>[],
    [unblockUser, blockUser, deleteUser, history, theme]
  )

  return (
    <Container>
      <SearchField
        style={{ alignSelf: 'flex-end', marginBottom: '35px' }}
        onSearch={(search) => {
          if (page !== 0) {
            setPage(0)
          }
          setSearch(search)
        }}
      />
      {status !== 'loading' ? (
        <Table
          columns={columns}
          records={data ? data.items : []}
          order={ordering}
          onOrder={setOrdering}
        />
      ) : (
        <SkeletonTable />
      )}
      {status === 'success' && data && data.items.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '75px' }}
          currentPage={page}
          totalPages={data.pagination.total_pages}
          onPageSelect={setPage}
        />
      )}
      {status === 'success' && data && data.items.length === 0 && (
        <Subtitle text="Nessun utente trovato" color="gray100" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`

const StatusDot = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 18px;
  height: 18px;
  border-radius: 18px;
`

import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Button, Divider, SectionTitle, Checkbox, Description, Input } from '@duckma/react-ds'

import { useRequest } from '../../hooks/useRequest'
import { api, CreateItem } from '../../data/api'
import { ImagesSection } from './components/ImagesSection'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { InfoSection } from './components/InfoSection'
import { PointsSection } from './components/PointsSection'
import { CategorySection } from './components/CategorySection'
import { RequiredSection } from './components/RequiredSection'

export const ItemDetail = () => {
  const { id } = useParams()
  const createMode = !id

  const [getItem, { status, data }] = useRequest(api.getItem)
  const [createItem, { status: createStatus }] = useRequest(api.createItem)
  const [updateItem, { status: updateStatus }] = useRequest(api.updateItem)

  const [values, setValues] = useState<Partial<CreateItem>>({
    mandatory: false,
    requires: [],
    doc_tags: [],
  })
  const [areInfoValid, setInfoValid] = useState(true)
  const [areImagesValid, setImagesValid] = useState(true)

  useEffect(() => {
    if (!createMode && id && status === 'idle') {
      getItem({ id })
    }
  }, [createMode, status, getItem, id])

  useSuccessToast(createStatus, 'Voce creata con successo', '/items')
  useSuccessToast(updateStatus, 'Voce modificata con successo')

  useEffect(() => {
    if (data) {
      if (data.doc_tags) console.log('Data has doc_tags!', data.doc_tags)
      setValues((values) => ({
        ...data,
        mandatory: data.mandatory,
        requires: data.requires?.map((r) => r.id) || [],
      }))
    }
  }, [data])

  const infoOnChange = useCallback((v, valid) => {
    setValues((current) => ({ ...current, ...v }))
    setInfoValid(valid)
  }, [])

  const categoryOnChange = useCallback((category) => {
    setValues((current) => ({ ...current, category }))
  }, [])

  const requiresOnChange = useCallback((requires) => {
    setValues((current) => ({ ...current, requires }))
  }, [])

  const imagesOnChange = useCallback((images) => {
    setValues((values) => ({ ...values, image: images[0] }))
    setImagesValid(images.length > 0)
  }, [])

  const pricingOnChange = useCallback(({ points, price, price_model, price_recurrence }) => {
    setValues((values) => ({
      ...values,
      points,
      price,
      price_model,
      price_recurrence,
    }))
  }, [])

  const pointsOnChange = useCallback((step: 'define' | 'build' | 'grow', points: string) => {
    setValues((values) => ({
      ...values,
      points: {
        ...values.points,
        [step]: parseInt(points),
      },
    }))
  }, [])

  const pointsDefineOnChange = useCallback((points: string) => {
    setValues((values) => ({
      ...values,
      points: {
        ...values.points,
        define: parseInt(points),
      },
    }))
  }, [])

  const pointsBuildOnChange = useCallback((points: string) => {
    setValues((values) => ({
      ...values,
      points: {
        ...values.points,
        build: parseInt(points),
      },
    }))
  }, [])

  const pointsGrowOnChange = useCallback((points: string) => {
    setValues((values) => ({
      ...values,
      points: {
        ...values.points,
        grow: parseInt(points),
      },
    }))
  }, [])

  return (
    <Container>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault()
          const data = ({
            ...values,
          } as unknown) as NonNullable<CreateItem>
          if (createMode) {
            createItem(data)
          } else {
            updateItem({
              id: id!,
              body: data,
            })
          }
        }}
      >
        <InfoSection onChange={infoOnChange} data={createMode ? undefined : data} />
        <SectionDivider />
        <CategorySection
          onChange={categoryOnChange}
          initialData={createMode ? undefined : data?.category}
        />
        <SectionDivider />
        <RequiredSection
          onChange={requiresOnChange}
          initialData={createMode ? undefined : data?.requires}
        />
        <SectionDivider />
        <SectionTitle text="Opzioni" style={{ marginBottom: '30px' }} />
        <Checkbox
          value={values.mandatory === true}
          leftText="Rendi la voce obbligatoria"
          onChange={(value) => setValues((val) => ({ ...val, mandatory: value }))}
          style={{ marginBottom: '10px' }}
        />
        <Description text="Seleziona questa opzione se la voce deve sempre essere inclusa. Può essere ad esempio utilizzata per costi di setup." />
        <Input
          name="tag-google-docs"
          value={values.doc_tags?.join(',')}
          onChange={(val) =>
            setValues((vals) => ({ ...vals, doc_tags: val.replace(' ', '').split(',') }))
          }
          style={{ marginTop: '30px' }}
        />
        <Description text="Tag da mantenere nel template d'offerta." />
        <SectionDivider />
        <SectionTitle text="Points" style={{ marginBottom: '30px' }} />
        {(createMode || data) && (
          <>
            <PointsSection
              points={`${data?.points?.define}` || undefined}
              onChange={pointsDefineOnChange}
            />
            <PointsSection
              points={`${data?.points?.build}` || undefined}
              onChange={pointsBuildOnChange}
            />
            <PointsSection
              points={`${data?.points?.grow}` || undefined}
              onChange={pointsGrowOnChange}
            />
          </>
        )}
        <SectionDivider />
        {(createMode || data) && (
          <ImagesSection initialValues={data ? [data.image] : []} onChange={imagesOnChange} />
        )}
        <SectionDivider />
        <FooterContainer>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Salva"
            radius={4}
            disabled={!areInfoValid || !areImagesValid}
            loading={status === 'loading'}
            style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
          />
        </FooterContainer>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  margin-bottom: 66px;
`

const SectionDivider = styled(Divider)`
  margin: 30px -54px;
`

import React, { useState, useMemo, useCallback, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Button, Divider, Input, Label, Radios, Tabs } from '@duckma/react-ds'
import _ from 'lodash'

import { ControlledField, ControlledForm } from '../../components/ControlledForm'
import { useRequest } from '../../hooks/useRequest'
import { api, UpdateUser } from '../../data/api'
import { requiredValidator } from '../../utils/validators'
import { useParams } from 'react-router-dom'
import { dateFormatter } from '../../utils/formatters'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { AssociationSection } from './components/AssociationSection'
import { UsersSection } from './components/UsersSection'
import { PriceListSection } from './components/PriceListSection'
import { SessionContext } from '../../components/MainLayout'

export const UserDetail = () => {
  const { id } = useParams<{ id: string }>()
  const { user } = useContext(SessionContext)
  const [getUser, { status, data }] = useRequest(api.getUser)
  const [updateUser, { status: updateStatus }] = useRequest(api.updateUser)

  const [values, setValues] = useState<Partial<UpdateUser>>({})
  const [valid, setValid] = useState(true)
  const canSetPriceList =
    user?.role === 'admin' || (user?.role === 'seller' && data?.role === 'user')
  const [currentTab, setCurrentTab] = useState<'users_list' | 'price_list'>(
    canSetPriceList ? 'price_list' : 'users_list'
  )
  const [multiplierPercent, setMultiplierPercent] = useState(data?.multiplier_percent)

  const initialValues = useMemo(
    () => (data ? _.omit(data, ['seller', 'multiplier_percent']) : null),
    [data]
  )
  const initialAssociationData = useMemo(() => (data ? _.pick(data, ['seller']) : null), [data])
  const onChange = useCallback((v, valid) => {
    setValues((vals) => ({ ...vals, ...v }))
    setValid(valid)
  }, [])

  useEffect(() => {
    if (status === 'idle') {
      getUser({ id })
    }
  }, [status, getUser, id])

  const onAssociationChange = useCallback((data) => {
    setValues((v) => ({ ...v, ...data }))
  }, [])

  useEffect(() => {
    if (data) {
      setValues((v) => ({ ...v, seller: data.seller?.id }))
      setMultiplierPercent(data.multiplier_percent)
    }
  }, [data])

  useSuccessToast(updateStatus, 'Dati modificati con successo')

  return (
    <Container>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault()
          let fields = [
            'first_name',
            'last_name',
            'phone_number',
            'business_name',
            'type',
            'tax_code_or_vat_code',
          ]
          if (data?.role === 'user') {
            fields.push('seller')
          }
          updateUser({
            id,
            body: {
              ...(_.pick(values, fields) as NonNullable<UpdateUser>),
              multiplier_percent: multiplierPercent,
            },
          })
        }}
      >
        <FieldsContainer>
          <ControlledForm initialValues={initialValues} onChange={onChange}>
            <ControlledField name="indirizzo-email" fieldName="email" disabled />
            <ControlledField
              name="data-di-iscrizione"
              fieldName="created_at"
              disabled
              formatter={dateFormatter}
            />
            <ControlledField name="nome" fieldName="first_name" validator={requiredValidator()} />
            <ControlledField name="cognome" fieldName="last_name" validator={requiredValidator()} />
            <ControlledField name="nome-azienda" fieldName="business_name" />
            <ControlledField name="numero-di-telefono" fieldName="phone_number" />
            <div>
              <Label text="Tipo" />
              <SpacedRadios
                value={values.type || ''}
                onChange={(t) =>
                  setValues((v) => ({
                    ...v,
                    type: t as 'private' | 'company',
                    tax_code_or_vat_code: '',
                  }))
                }
                choices={[
                  { key: 'private', label: 'Privato' },
                  { key: 'company', label: 'Company' },
                ]}
              />
            </div>
            <Input
              name="tax_code_or_vat_code"
              value={values.tax_code_or_vat_code}
              onChange={(tax_code_or_vat_code) =>
                setValues((v) => ({ ...v, tax_code_or_vat_code }))
              }
            />
          </ControlledForm>
        </FieldsContainer>
        {initialAssociationData && data?.role === 'user' && (
          <>
            <SectionDivider style={{ marginBottom: '30px' }} />
            <AssociationSection
              initialData={initialAssociationData}
              onChange={onAssociationChange}
            />
          </>
        )}
        {data?.role === 'seller' && (
          <>
            <SectionDivider style={{ marginBottom: '30px' }} />
            <Tabs
              currentTab={currentTab}
              onTabChange={(tab) => setCurrentTab(tab as 'users_list' | 'price_list')}
              tabs={[
                ...(canSetPriceList
                  ? [
                      {
                        key: 'price_list',
                        label: 'Listino Prezzi',
                        child: (
                          <PriceListSection
                            multiplierPercent={multiplierPercent}
                            onChange={setMultiplierPercent}
                          />
                        ),
                      },
                    ]
                  : []),
                {
                  key: 'users_list',
                  label: 'Utenti Associati',
                  child: <UsersSection user={data} />,
                },
              ]}
            />
          </>
        )}
        <div style={{ flexGrow: 1 }} />
        <FooterContainer>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Salva"
            radius={4}
            disabled={!(valid && data && (data.role !== 'user' || values.seller))}
            loading={status === 'loading'}
            style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
          />
        </FooterContainer>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  margin-bottom: 66px;
`

const SectionDivider = styled(Divider)`
  margin-left: -54px;
  margin-right: -54px;
`

const SpacedRadios = styled(Radios)`
  margin-top: 8px;
`

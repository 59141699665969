import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { api } from '../../data/api'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Table, Ordering, Paginator, Subtitle, ColorName } from '@duckma/react-ds'
import { useRequest } from '../../hooks/useRequest'
import { Column } from '@duckma/react-ds'
import { toast } from 'react-toastify'
import { SearchField } from '../../components/SearchField'
import { SkeletonTable } from '../../components/SkeletonTable'
import { Prospect, ProspectStatus } from '../../data/models'
import { dateTimeFormatter } from '../../utils/formatters'
import { TableActions } from '../../components/TableActions'
import { theme } from '../../styles/theme'
import { Link } from '../auth/components/Link'

const renderStatusDot = (status: ProspectStatus) => {
  switch (status) {
    case 'saved':
      return (
        <StatusDot
          aria-label="Offerta salvata, non richiesta"
          data-microtip-position="top"
          role="tooltip"
          color="gray100"
        />
      )
    case 'requested':
      return (
        <StatusDot
          aria-label="Offerta richiesta"
          data-microtip-position="top"
          role="tooltip"
          color="warning100"
        />
      )
    case 'accepted':
      return (
        <StatusDot
          aria-label="Offerta accettata"
          data-microtip-position="top"
          role="tooltip"
          color="success100"
        />
      )
    case 'declined':
      return (
        <StatusDot
          aria-label="Offerta rifiutata"
          data-microtip-position="top"
          role="tooltip"
          color="danger100"
        />
      )
  }
}

export const ProspectsPage: React.FC<{ status: string }> = ({ status }) => {
  const history = useHistory()
  const [getProspects, { status: getStatus, data }] = useRequest(api.getProspects)
  const [deleteProspect, { data: deleteData }, resetDelete] = useRequest(api.deleteProspect)

  const [ordering, setOrdering] = useState<Ordering<Prospect>>({
    key: 'title',
    direction: 'asc',
  })
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [page, setPage] = useState<number>(0)

  const getProspectsWithParams = useCallback(
    () =>
      getProspects({
        order_by: ordering.key,
        order_direction: ordering.direction,
        search_text: search,
        page,
        page_size: 10,
        status,
      }),
    [getProspects, ordering, page, search, status]
  )

  useEffect(() => {
    getProspectsWithParams()
  }, [getProspectsWithParams])
  useEffect(() => {
    if (deleteData != null) {
      toast('Contenuto eliminato con successo', { type: 'success' })
      getProspectsWithParams()
      resetDelete()
    }
  }, [deleteData, resetDelete, getProspectsWithParams])

  const columns = useMemo(
    () =>
      [
        { key: 'title', name: 'Titolo', orderable: true },
        {
          key: 'user.last_name',
          name: 'Autore',
          orderable: true,
          render: ({ user }) => (
            <Link to={`/users/${user.id}`} text={`${user.first_name} ${user.last_name}`} />
          ),
        },
        {
          key: 'status',
          name: 'Stato',
          orderable: true,
          render: ({ status }) => renderStatusDot(status),
        },
        {
          key: 'created_at',
          name: 'Data Inserimento',
          orderable: true,
          render: ({ created_at }) => dateTimeFormatter(created_at),
        },
        {
          key: 'actions',
          name: 'Azioni',
          render: (prospect) => (
            <TableActions
              id={prospect.id}
              editButton={[(id: string) => history.push(`/prospects/${id}`)]}
              deleteButton={[
                (id: string) => deleteProspect({ id }),
                `Sei sicuro di voler cancellare il contenuto (${prospect.title})? L'azione è irreversibile.`,
                undefined,
              ]}
            />
          ),
        },
      ] as Column<Prospect>[],
    [deleteProspect, history]
  )

  return (
    <Container>
      <SearchField
        style={{ alignSelf: 'flex-end', marginBottom: '35px' }}
        onSearch={(search) => {
          if (page !== 0) {
            setPage(0)
          }
          setSearch(search)
        }}
      />
      {getStatus !== 'loading' ? (
        <Table
          columns={columns}
          records={data ? data.items : []}
          order={ordering}
          onOrder={setOrdering}
        />
      ) : (
        <SkeletonTable />
      )}
      {getStatus === 'success' && data && data.items.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '75px' }}
          currentPage={page}
          totalPages={data.pagination.total_pages}
          onPageSelect={setPage}
        />
      )}
      {getStatus === 'success' && data && data.items.length === 0 && (
        <Subtitle text="Nessuna offerta trovata" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`

const StatusDot = styled.div<{ color: ColorName }>`
  background-color: ${(props) => theme[props.color]};
  width: 18px;
  height: 18px;
  border-radius: 18px;
`

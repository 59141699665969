import React, { useState, useMemo, useCallback, useContext } from 'react'
import styled from 'styled-components'
import { Button, Divider, Input, Label, Radios } from '@duckma/react-ds'

import { ControlledField, ControlledForm } from '../../components/ControlledForm'
import { useRequest } from '../../hooks/useRequest'
import { api, CreateUser as TCreateUser } from '../../data/api'
import { requiredValidator, emailValidator } from '../../utils/validators'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { PasswordField } from '../../components/PasswordField'
import { RoleSection } from './components/RoleSection'
import { SessionContext } from '../../components/MainLayout'

export const CreateUser = () => {
  const { user } = useContext(SessionContext)
  const [request, { status }] = useRequest(api.createUser)

  const [values, setValues] = useState<Partial<TCreateUser>>({ password: '' })
  const [valid, setValid] = useState(true)
  const [passwordValid, setPasswordValid] = useState(false)
  const [roleValid, setRoleValid] = useState(true)

  const initialValues = useMemo(() => ({}), [])
  const onChange = useCallback((values, valid) => {
    setValues((v) => ({ ...v, ...values }))
    setValid(valid)
  }, [])
  const onPasswordChange = useCallback((password, valid) => {
    setValues((v) => ({ ...v, password }))
    setPasswordValid(valid)
  }, [])

  const roleChange = useCallback((role, sellerId) => {
    setValues((current) => ({
      ...current,
      role,
      seller: sellerId,
    }))
    setRoleValid(role !== 'user' || sellerId !== undefined)
  }, [])

  if (user?.role === 'seller' && values?.role === undefined) {
    roleChange('user', user.id)
  }

  useSuccessToast(status, `Agenzia aggiunta con successo`, '/users')

  const initialUser = useMemo(() => (user?.role === 'seller' ? user : null), [user])

  return (
    <Container>
      {user?.role === 'admin' && <RoleSection initialUser={initialUser} onChange={roleChange} />}
      <SectionDivider />
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault()
          request(values as NonNullable<TCreateUser>)
        }}
        autoComplete="off"
      >
        <FieldsContainer>
          <ControlledForm initialValues={initialValues} onChange={onChange}>
            <ControlledField name="nome" fieldName="first_name" validator={requiredValidator()} />
            <ControlledField name="cognome" fieldName="last_name" validator={requiredValidator()} />
            <ControlledField name="numero_di_telefono" fieldName="phone_number" />
            <ControlledField name="nome_azienda" fieldName="business_name" />
            <div>
              <Label text="Tipo" />
              <SpacedRadios
                value={values.type || ''}
                onChange={(t) =>
                  setValues((v) => ({
                    ...v,
                    type: t as 'private' | 'company',
                    tax_code_or_vat_code: '',
                  }))
                }
                choices={[
                  { key: 'private', label: 'Privato' },
                  { key: 'company', label: 'Company' },
                ]}
              />
            </div>
            <Input
              name="tax_code_or_vat_code"
              value={values.tax_code_or_vat_code}
              onChange={(tax_code_or_vat_code) =>
                setValues((v) => ({ ...v, tax_code_or_vat_code }))
              }
            />
            <ControlledField
              name="indirizzo-email"
              fieldName="email"
              validator={emailValidator()}
            />
            <PasswordField value={values.password || ''} onChange={onPasswordChange} />
          </ControlledForm>
        </FieldsContainer>
        <div style={{ flexGrow: 1 }} />
        <FooterContainer>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Salva"
            radius={4}
            disabled={!valid || !passwordValid || !roleValid}
            loading={status === 'loading'}
            style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
          />
        </FooterContainer>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 54px;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  margin-bottom: 66px;
`

const SectionDivider = styled(Divider)`
  margin-left: -54px;
  margin-right: -54px;
`

const SpacedRadios = styled(Radios)`
  margin-top: 8px;
`

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Modal, Button } from '@duckma/react-ds'
import { MediaCard } from './components/MediaCard'
import { api } from '../../data/api'
import { useRequest } from '../../hooks/useRequest'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { useHistory } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

export const UpdateMediaModal: React.FC<{
  mediaId: string
}> = ({ mediaId }) => {
  const history = useHistory()
  const [request, { data }] = useRequest(api.getMedia)
  const [requestUpdate, { status: updateStatus }] = useRequest(api.updateMedia)
  const [title, setTitle] = useState('')

  useEffect(() => {
    request({ id: mediaId })
  }, [request, mediaId])

  useEffect(() => {
    if (data) {
      setTitle(data.title)
    }
  }, [data])

  useSuccessToast(updateStatus, 'Dati modificati con successo', '/media')

  return (
    <StyledModal open onClose={() => history.push('/media')} title="Modifica Immagine">
      <Content>
        {data ? (
          <MediaCard imageUrl={data.thumbnail_url || data.url} title={title} onChange={setTitle} />
        ) : (
          <Skeleton />
        )}
        <div style={{ width: '100%', display: 'flex', flexGrow: 0 }}>
          <div style={{ flexGrow: 1 }} />
          <Button
            text="Salva"
            radius={4}
            loading={updateStatus === 'loading'}
            disabled={title.length === 0}
            onClick={() => {
              requestUpdate({ id: mediaId, body: { title, type: 'image' } })
            }}
            style={{ flexGrow: 0, flexBasis: '30%', margin: '35px' }}
          />
        </div>
      </Content>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  position: fixed;
  left: calc(50vw - 300px);
  right: calc(50vw - 300px);
  top: 10vh;
  bottom: 10vh;
  outline: none;
  border-radius: 10px;
  > * {
    z-index: 2;
  }
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  height: calc(80vh - 64px);
  > * {
    width: calc(100% - 100px - 50px);
    border-radius: 10px;
  }
  > :first-child {
    flex-grow: 1;
  }
`

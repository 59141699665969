import React, { useState, useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import {
  Divider,
  SectionTitle,
  Subtitle,
  Input,
  Button,
  Label,
  Table,
  Column,
  Ordering,
  Checkbox,
} from '@duckma/react-ds'

import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { Link } from '../auth/components/Link'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { Category, Media, ProspectItem } from '../../data/models'
import { useNetworkQueue } from '../../hooks/useQueue'
import _ from 'lodash'
import { FormattedPrice } from './components/FormattedPrice'
import { useTotalPrices } from '../../hooks/useTotalPrices'
import { priceRounder } from '../../utils/formatters'

const categoryTranslations = {
  [Category.COMPONENTS]: 'Componenti',
  [Category.GRAPHICS]: 'Grafica',
  [Category.PLATFORMS]: 'Piattaforme',
  [Category.SERVICES]: 'Servizi',
}

interface Offer {
  offer_comment: string
  attachments?: string[]
}

const ordering: Ordering<ProspectItem> = { key: 'id', direction: 'asc' }

const AttachmentList: React.FC<{ attachments?: Media[] }> = ({ attachments = [] }) => (
  <span>
    {attachments.map((a, i, all) => (
      <span key={i} style={{ fontFamily: 'Open Sans', fontSize: '.8em', fontWeight: 600 }}>
        <a href={a.url} target="_blank" rel="noopener noreferrer">
          {a.title}
        </a>
        {all.length !== i + 1 && ', '}
      </span>
    ))}
  </span>
)

export const ProspectDetail = () => {
  const { id } = useParams<{ id: string }>()
  const createMode = !id

  const [getProspect, { status, data }] = useRequest(api.getProspect)
  const [sendOffer, { status: sendOfferStatus }] = useRequest(api.sendOffer)
  const [reopenOffer, { status: reopenOfferStatus }] = useRequest(api.reopenOffer)
  const [createProspect, { status: createProspectStatus, data: createProspectData }] = useRequest(
    api.createProspect
  )

  const [offer, setOffer] = useState<Offer>({ offer_comment: '' })
  const [readCheck, setReadCheck] = useState(false)

  const onComplete = useCallback((data: Media) => {
    setOffer((o) => ({ ...o, attachments: [...(o.attachments ?? []), data.id] }))
  }, [])
  const [loadQueue] = useNetworkQueue(api.createMedia, onComplete)

  useEffect(() => {
    if (status === 'success' && data?.offer_comment) {
      setOffer({ offer_comment: data.offer_comment })
    }
  }, [data, status])

  useEffect(() => {
    if (!createMode && id && status === 'idle') {
      getProspect({ id })
    }
  }, [createMode, status, getProspect, id])

  useEffect(() => {
    if (sendOfferStatus === 'success') {
      getProspect({ id })
    }
  }, [getProspect, id, sendOfferStatus])

  useEffect(() => {
    if (reopenOfferStatus === 'success') {
      getProspect({ id })
    }
  }, [getProspect, id, reopenOfferStatus])

  useEffect(() => {
    if (createProspectStatus === 'success' && createProspectData) {
      window.open(`/prospects/${createProspectData.id}`, '_blank')
    }
  }, [createProspectStatus, createProspectData])

  useSuccessToast(sendOfferStatus, 'Offerta inviata con successo.')

  const totalPrices = useTotalPrices(data?.items || [], data?.multiplier_percent)

  const columns = useMemo(
    () =>
      [
        {
          key: 'category',
          name: 'Categoria',
          orderable: false,
          render: ({ category }) => _.get(categoryTranslations, category, _.capitalize(category)),
        },
        { key: 'title', name: 'Titolo', orderable: false },
        {
          key: 'price',
          name: 'Prezzo',
          orderable: false,
          render: (item) => (
            <FormattedPrice
              item={item}
              totalPrices={totalPrices}
              multiplierPercent={data?.multiplier_percent}
            />
          ),
        },
        {
          key: 'customization',
          name: 'Personalizzazione',
          orderable: false,
          render: ({ customization, customization_notes, customization_rating }) =>
            customization ? customization_notes : <span style={{ color: '#AAAA' }}>No</span>,
        },
      ] as Column<ProspectItem>[],
    [data, totalPrices]
  )

  return (
    <Container>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault()
        }}
      >
        <InfoGrid>
          <Label text="Autore:" />
          <Link
            to={`/users/${data?.user.id}`}
            text={`${data?.user.first_name} ${data?.user.last_name}`}
          />
          <Label text="Nome app:" />
          <SectionTitle text={data && data.title} />
          <Label text="Descrizione:" />
          <Subtitle text={data && data.short_description} />
          {data && data.customer_name && (
            <>
              <Label text="Nome cliente:" />
              <Subtitle text={data.customer_name} />
            </>
          )}
          {data && data.company_name && (
            <>
              <Label text="Nome azienda:" />
              <Subtitle text={data.company_name} />
            </>
          )}
          {data && data.financial_plan && (
            <>
              <Label text="Piano finanziario richiesto: " />
              <Subtitle
                text={`${data.financial_plan.percentage_upfront}% subito (50% inizio e 50% consegna), restante distribuito in ${data.financial_plan.duration_months} mesi.`}
              />
            </>
          )}
          {data && data.offer_file_url && (
            <>
              <Label text="Link offerta (Google Docs):" />
              <Subtitle>
                <a href={data.offer_file_url} target="_blank" rel="noopener noreferrer">
                  {data.offer_file_url}
                </a>
              </Subtitle>
            </>
          )}
          <Label text="Allegati:" />
          {data && <AttachmentList attachments={data.attachments} />}
          {data && data.offer_comment && (
            <>
              <Label text="Commenti offerta:" />
              <Subtitle text={data.offer_comment} />
            </>
          )}
          {data && data.offer_attachments?.length > 0 && (
            <>
              <Label text="Allegati offerta:" />
              <AttachmentList attachments={data.offer_attachments} />
            </>
          )}
          <Label text="Prezzo totale:" />
          <Label>
            {priceRounder(totalPrices.oneShotTotal) || 0}
            {' €'}
          </Label>
          <Label text="Prezzo ricorrente:" />
          <Label>
            {priceRounder(totalPrices.recurrentTotal) || 0}
            {' € / mese'}
          </Label>
        </InfoGrid>
        <br />
        <SectionDivider />
        <SectionTitle text="Voci selezionate" style={{ margin: '30px 0' }} />
        {data?.items && (
          <Table<ProspectItem> columns={columns} records={data.items} order={ordering} />
        )}
        <br />
        <SectionDivider />
        <SectionTitle
          text={
            data?.status === 'saved'
              ? 'Offerta non richiesta'
              : data?.status === 'requested'
              ? 'Invia Offerta'
              : 'Offerta Inviata'
          }
          style={{ marginTop: '30px', marginBottom: '30px' }}
        />
        {data?.status === 'requested' && (
          <>
            <Input
              rows={5}
              value={offer.offer_comment}
              name="offer_comment"
              label="Commento all'offerta"
              placeholder="Commento (sarà il contenuto dell'email)"
              onChange={(value) => {
                setOffer({
                  ...offer,
                  offer_comment: value as string,
                })
              }}
            />
            <Checkbox
              value={readCheck}
              onChange={setReadCheck}
              rightText="Ho ricontrollato il contenuto del documento allegato, che verrà inviato come PDF all'utente."
            />
            <SendButton
              text="Invia Offerta"
              radius={50}
              onClick={() => {
                sendOffer({ id, body: { ...offer } })
              }}
              loading={sendOfferStatus === 'loading'}
              disabled={!offer.offer_comment || loadQueue.length > 0 || !readCheck}
            />
          </>
        )}
        {data?.status === 'accepted' && (
          <>
            <SendButton
              text="Riapri Offerta"
              color="warning"
              radius={50}
              onClick={() => {
                reopenOffer({ id })
              }}
            />
          </>
        )}
        {data && (
          <SendButton
            text="Clona Offerta"
            tooltip="Clona offerta nel tuo account"
            color="secondary"
            size="small"
            outline
            radius={50}
            onClick={() => {
              createProspect({
                ...data,
                user: undefined,
                id: undefined,
                title: `[CLONE] ${data.title}`,
              })
            }}
          />
        )}
      </FormContainer>
    </Container>
  )
}

const InfoGrid = styled.div`
  display: grid;
  align-items: center;
  grid-gap: 8px;
  grid-template-columns: auto 1fr;
`

const SendButton = styled(Button)`
  margin: 16px 0px;
  padding: 8px 16px;
  width: fit-content;
`

const Container = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-items: flex-between;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const SectionDivider = styled(Divider)`
  margin-left: -54px;
  margin-right: -54px;
`

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { SectionTitle, Radios } from '@duckma/react-ds'

import { User, Role } from '../../../data/models'
import { Select } from '../../../components/Select'
import { api } from '../../../data/api'

type RoleChoice = 'admin' | 'seller' | 'user'
type Props = {
  initialUser: null | User
  onChange: (role: RoleChoice, userId?: string) => void
  disableUserChange?: boolean
}

const roleChoices: { key: Role; label: string }[] = [
  { key: 'admin', label: 'Admin' },
  { key: 'seller', label: 'Agenzia' },
  { key: 'user', label: 'Utente' },
]

export const RoleSection: React.FC<Props> = ({
  initialUser,
  onChange,
  disableUserChange = false,
}) => {
  const [user, setUser] = useState<{ value: string; label: string } | null>(null)
  const [role, setRole] = useState<RoleChoice>('user')

  useEffect(() => {
    if (initialUser) {
      setUser({
        value: initialUser.id,
        label: `${initialUser.first_name} ${initialUser.last_name}`,
      })
      if (initialUser.role === 'seller') {
        setRole('seller')
      }
    }
  }, [initialUser])

  useEffect(() => {
    onChange(role, user?.value)
  }, [onChange, user, role])

  return (
    <Container>
      <SectionTitle text="Tipologia utente" style={{ marginBottom: '30px' }} />
      <Radios
        choices={roleChoices}
        value={role}
        disabled={initialUser?.role === 'seller'}
        onChange={(choice) => {
          setRole(choice as RoleChoice)
          setUser(null)
        }}
      />
      {role === 'user' && (
        <Select
          style={{ marginTop: '30px' }}
          name="agenzia"
          label="Agenzia"
          getItemsPromise={(search_text) =>
            api
              .getUsers({ roles: 'seller', search_text })
              .then((data) =>
                data.items.map((u) => ({ value: u.id, label: `${u.first_name} ${u.last_name}` }))
              )
          }
          onChange={(entry) => {
            if (!disableUserChange) setUser(entry)
          }}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  flex-direction: column;
`

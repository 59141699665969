import React, { useEffect, useState, useCallback } from 'react'
import { api, CreateMedia } from '../../data/api'
import styled from 'styled-components'
import { Modal, Tabs, Button } from '@duckma/react-ds'
import { Media } from '../../data/models'
import { CreateTab } from './components/CreateTab'
import { LibraryTab } from './components/LibraryTab'
import { useNetworkQueue } from '../../hooks/useQueue'

export const MediaSelector: React.FC<{
  open: boolean
  multi?: boolean
  onClose?: () => void
  onSelect: (media: Media[]) => void
  hideIds?: string[]
}> = ({ open, multi = false, onClose = () => {}, onSelect = () => {}, hideIds = [] }) => {
  const [currentTab, setCurrentTab] = useState('create')
  const [media, setMedia] = useState<Media[]>([])
  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const onComplete = useCallback(
    (data: Media) => {
      setToUpload([])
      onSelect([data])
    },
    [onSelect]
  )
  const [loadQueue, { enqueue }] = useNetworkQueue(api.createMedia, onComplete)

  const [toUpload, setToUpload] = useState<CreateMedia[]>([])

  useEffect(() => {
    setConfirmDisabled(true)
    setToUpload([])
    setMedia([])
  }, [currentTab])

  useEffect(() => {
    setConfirmDisabled(true)
    setToUpload([])
    setMedia([])
    document.body.style.overflow = open ? 'hidden' : 'unset'
  }, [open])

  useEffect(() => {
    if (loadQueue.length === 0) {
      onClose()
    }
  }, [loadQueue, onClose])

  useEffect(() => {
    if (media.length === 0) {
      setConfirmDisabled(true)
    }
  }, [media])

  return (
    <StyledModal open={open} onClose={onClose} title="Aggiungi Immagine">
      <Content>
        <TabsContainer>
          <Tabs
            currentTab={currentTab}
            onTabChange={(tabId) => {
              if (loadQueue.length === 0) {
                setCurrentTab(tabId)
              }
            }}
            tabs={[
              {
                key: 'create',
                label: 'Carica Media',
                child: (
                  <CreateTab
                    setConfirmDisabled={setConfirmDisabled}
                    setToUpload={setToUpload}
                    multi={multi}
                  />
                ),
              },
              {
                key: 'library',
                label: 'Libreria Media',
                child: (
                  <LibraryTab
                    multi={multi}
                    setMedia={(media) => {
                      setMedia(media)
                      if (media.length > 0 && confirmDisabled) {
                        setConfirmDisabled(false)
                      }
                    }}
                    hideIds={hideIds}
                    selected={media}
                  />
                ),
              },
            ]}
          />
        </TabsContainer>
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ flexGrow: 1 }} />
          <Button
            text={currentTab === 'create' ? 'Aggiungi a libreria media' : 'Seleziona media'}
            radius={4}
            loading={loadQueue.length > 0}
            disabled={confirmDisabled}
            onClick={() => {
              if (toUpload.length > 0) {
                toUpload.forEach(enqueue)
                return
              }
              onSelect(media)
              onClose()
            }}
            style={{ flexGrow: 0, flexBasis: '30%', margin: '35px' }}
          />
        </div>
      </Content>
    </StyledModal>
  )
}

const TabsContainer = styled.div`
  margin-left: 20px;
  margin-right: 34px;
  width: calc(100% - 20px - 34px);
  height: 100%;
`

const StyledModal = styled(Modal)`
  position: fixed;
  top: 5vh;
  left: 10vw;
  right: 10vw;
  bottom: 5vh;
  outline: none;
  border-radius: 10px;
  > * {
    z-index: 2;
  }
`

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  calc(90vw - 64px);
`

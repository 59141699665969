import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { SectionTitle } from '@duckma/react-ds'

import { MinimalItem } from '../../../data/models'
import { Select } from '../../../components/Select'
import { api } from '../../../data/api'

type Props = {
  initialData?: MinimalItem[]
  onChange: (items: string[]) => void
}

export const RequiredSection: React.FC<Props> = ({ initialData, onChange }) => {
  const [items, setItems] = useState<{ value: string; label: string }[]>([])

  useEffect(() => {
    setItems(
      initialData
        ? initialData.map((i) => ({
            value: i.id,
            label: i.title,
          }))
        : []
    )
  }, [initialData])

  useEffect(() => {
    if (items) {
      onChange(items.map((i) => i.value))
    }
  }, [onChange, items])

  return (
    <>
      <SectionTitle text="Voci obbligatorie" />
      <Container>
        <StyledSelect
          name="required"
          placeholder="Specifica le voci richieste"
          label="Voci richieste *"
          multi
          getItemsPromise={(search: string) =>
            api
              .getItemsByTitle(search)
              .then(({ items }) => items.map((i) => ({ value: i.id, label: i.title })))
          }
          value={items}
          onMultipleChange={setItems}
        />
      </Container>
    </>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10%;
  padding-top: 30px;
`

const StyledSelect = styled(Select)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 13px;
  width: 50%;
  > div {
    border: 0.5px solid #828894;
    border-radius: 2px;
  }
`

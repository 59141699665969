import React, { useEffect, useState } from 'react'
import { api } from '../../../data/api'
import { Image } from '../../../components/Image'
import styled from 'styled-components'
import { Subtitle } from '@duckma/react-ds'
import { useRequest } from '../../../hooks/useRequest'
import { SearchField } from '../../../components/SearchField'
import { Media } from '../../../data/models'
import Skeleton from 'react-loading-skeleton'

export const LibraryTab: React.FC<{
  multi: boolean
  setMedia: (media: Media[]) => void
  selected: Media[]
  hideIds: string[]
}> = ({ multi, setMedia, selected, hideIds }) => {
  const [getMedias, { status, data }] = useRequest(api.getMedias)

  const [search, setSearch] = useState<string | undefined>(undefined)

  useEffect(
    () =>
      getMedias({
        order_by: 'created_at',
        order_direction: 'desc',
        search_text: search,
        page: 0,
        page_size: 20,
      }),
    [getMedias, search]
  )

  return (
    <TabContainer>
      <SearchField
        style={{
          alignSelf: 'flex-end',
          marginBottom: '35px',
          marginTop: '23px',
          marginRight: '50px',
          width: '50%',
        }}
        onSearch={setSearch}
      />
      <ImageContainer>
        {status === 'loading' && <Skeleton width="225px" height="150px" count={5} />}
        {status === 'success' &&
          data &&
          data.items
            .filter((media) => hideIds.find((h) => h === media.id) === undefined)
            .map((media) => (
              <Image
                key={media.id}
                url={media.thumbnail_url || media.url}
                title={media.title}
                sizeBytes={media.size_bytes}
                selected={selected.find((sel) => sel.id === media.id) !== undefined}
                onClick={() =>
                  selected.find((sel) => sel.id === media.id)
                    ? setMedia(selected.filter((sel) => sel.id !== media.id))
                    : multi
                    ? setMedia([...selected, media])
                    : setMedia([media])
                }
                width={
                  selected.find((sel) => sel.id === media.id) !== undefined ? '300px' : '269px'
                }
                height={
                  selected.find((sel) => sel.id === media.id) !== undefined ? '200px' : '168px'
                }
              />
            ))}
      </ImageContainer>
      {status === 'success' && data && data.items.length === 0 && (
        <Subtitle
          text={
            search
              ? 'Nessun media trovato'
              : 'La libreria media è vuota. Aggiungi delle immagini e le vedrai qui!'
          }
          style={{ marginBottom: '35px' }}
        />
      )}
    </TabContainer>
  )
}

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  max-height: 60vh;
`

const ImageContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 1rem;
  justify-items: center;
`

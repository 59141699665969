import React from 'react'
import styled from 'styled-components'
import { SectionTitle, Label, NumericInput } from '@duckma/react-ds'

type Props = {
  multiplierPercent?: number
  onChange: (multiplierPercent: number) => void
}

export const PriceListSection: React.FC<Props> = ({ multiplierPercent, onChange }) => {
  return (
    <Section>
      <SectionTitle text={`Listino Prezzi`} />
      <Container>
        <PriceItem>
          <PriceLabel text="Moltiplicatore prezzi" />
          <NumericInput
            name=""
            label=""
            placeholder="100"
            value={multiplierPercent}
            onChange={onChange}
          />
          <DefaultLabel text="Moltiplica tutti i prezzi per questa percentuale. 100 li lascia inalterati" />
        </PriceItem>
      </Container>
    </Section>
  )
}

const DefaultLabel = styled(Label)`
  font-size: 0.7em;
  color: #777;
  min-width: 128px;
  margin-left: 8px;
`

const PriceLabel = styled(Label)`
  width: 20vw;
  min-width: 128px;
`

const PriceItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`

const Section = styled.div`
  padding: 16px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 30px;
`

import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Column, Table, Ordering, Paginator, Subtitle, Tabs } from '@duckma/react-ds'
import { toast } from 'react-toastify'
import _ from 'lodash'

import { api } from '../../data/api'
import { useRequest } from '../../hooks/useRequest'
import { SearchField } from '../../components/SearchField'
import { SkeletonTable } from '../../components/SkeletonTable'
import { Item, Category } from '../../data/models'
import { TableActions } from '../../components/TableActions'
import { TableOrdering } from '../../components/TableOrdering'

export const ItemsPage = () => {
  const history = useHistory()
  const [getItems, { status, data }] = useRequest(api.getItems)
  const [moveItem, { data: moveData }, resetMove] = useRequest(api.orderItem)
  const [deleteItem, { data: deleteData }, resetDelete] = useRequest(api.deleteItem)
  const [tab, setTab] = useState<string>(Object.values(Category)[0])

  const [ordering] = useState<Ordering<Item>>({
    key: 'order',
    direction: 'asc',
  })
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [page, setPage] = useState<number>(0)

  const getItemsWithParams = useCallback(
    () =>
      getItems({
        order_by: 'order',
        order_direction: 'asc',
        search_text: search,
        page,
        page_size: 100,
      }),
    [getItems, page, search]
  )

  useEffect(() => {
    getItemsWithParams()
  }, [getItemsWithParams])

  useEffect(() => {
    if (deleteData != null) {
      toast('Contenuto eliminato con successo', { type: 'success' })
      getItemsWithParams()
      resetDelete()
    }
  }, [deleteData, resetDelete, getItemsWithParams])

  useEffect(() => {
    if (moveData != null) {
      getItemsWithParams()
      resetMove()
    }
  }, [moveData, resetMove, getItemsWithParams])

  const columns = useMemo(
    () =>
      [
        {
          key: 'ordering',
          name: 'Ordinamento',
          render: (component) => {
            if (data) {
              return (
                <TableOrdering
                  id={component.id}
                  onUp={
                    component.order > 0
                      ? () => {
                          moveItem({ id: component.id, toPosition: component.order - 1 })
                        }
                      : undefined
                  }
                  onDown={
                    component.order < data!.items.length - 1
                      ? () => {
                          moveItem({ id: component.id, toPosition: component.order + 1 })
                        }
                      : undefined
                  }
                />
              )
            }
          },
        },
        { key: 'title', name: 'Titolo', orderable: true },
        {
          key: 'actions',
          name: 'Azioni',
          render: (component) => (
            <TableActions
              id={component.id}
              editButton={[(id: string) => history.push(`/items/${id}`)]}
              deleteButton={[
                (id: string) => deleteItem({ id }),
                `Sei sicuro di voler cancellare il contenuto (${component.title})? L'azione è irreversibile.`,
                undefined,
              ]}
            />
          ),
        },
      ] as Column<Item>[],
    [data, moveItem, history, deleteItem]
  )

  return (
    <Container>
      <SearchField
        style={{ alignSelf: 'flex-end', marginBottom: '35px' }}
        onSearch={(search) => {
          if (page !== 0) {
            setPage(0)
          }
          setSearch(search)
        }}
      />
      <Tabs
        currentTab={tab}
        tabs={Object.values(Category).map((category) => ({
          key: category,
          label: _.capitalize(category),
          child:
            status !== 'loading' ? (
              <Table
                columns={columns}
                records={data ? data.items.filter((i) => i.category === category) : []}
                order={ordering}
              />
            ) : (
              <SkeletonTable />
            ),
        }))}
        onTabChange={setTab}
      />
      {status === 'success' && data && data.items.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '75px' }}
          currentPage={page}
          totalPages={data.pagination.total_pages}
          onPageSelect={setPage}
        />
      )}
      {status === 'success' && data && data.items.length === 0 && (
        <Subtitle text="Nessun contenuto trovato" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

type Props = {
  points?: string
  onChange: (points: string) => void
}

export const PointsSection: React.FC<Props> = ({ points, onChange }) => {
  const [value, setValue] = useState<string>("1")

  useEffect(() => {
    onChange(value)
  }, [onChange, value])

  useEffect(() => {
    if (points) {
      setValue(points)
    }
  }, [points])

  return (
    <FieldsContainer>
      <SeekBar type="range" min="1" max="100" value={value} onChange={(event) => {
          const value = event.target.value;
          setValue(value);
          onChange(value);
        }}/>
    </FieldsContainer>
  )
}

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 20px;
  }
`

const SeekBar = styled.input``

import React, { useMemo, useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'

import { useDirty } from '../../../hooks/useDirty'
import {
  ControlledForm,
  ControlledField,
  ControlledHtmlEditor,
} from '../../../components/ControlledForm'
import { requiredValidator, requiredHtmlValidator } from '../../../utils/validators'
import _ from 'lodash'

type Props = {
  data:
    | {
        title: string
        short_description: string
        details_url: string
      }
    | null
    | undefined
  onChange: (info: {}, valid: boolean) => void
}

export const InfoSection: React.FC<Props> = ({ data, onChange }) => {
  const [values, setValues] = useState({ fields: {}, valid: true })

  const initialValues = useMemo(() => {
    if (data === undefined) {
      return {
        title: '',
        short_description: '',
        details_url: '',
      }
    }
    return data ? _.pick(data, ['title', 'short_description', 'details_url']) : null
  }, [data])

  useDirty(values.fields, initialValues, ['title', 'short_description'])

  useEffect(() => {
    onChange(values.fields, values.valid)
  }, [onChange, values])

  const onChangeInternal = useCallback((fields, valid) => setValues({ fields, valid }), [])

  return (
    <FieldsContainer>
      <ControlledForm initialValues={initialValues} onChange={onChangeInternal}>
        <ControlledField name="titolo_*" fieldName="title" validator={requiredValidator()} />
        <ControlledField name="url_informazioni" fieldName="details_url" />
        <ControlledHtmlEditor
          name="descrizione"
          fieldName="short_description"
          validator={requiredHtmlValidator()}
        />
      </ControlledForm>
    </FieldsContainer>
  )
}

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 20px;
  }
`

import React from 'react'
import styled from 'styled-components'
import { Button } from '@duckma/react-ds'

type Props = {
  id: string
  onUp?: () => void
  onDown?: () => void
}
export const TableOrdering = ({ id, onUp, onDown }: Props) => {
  return (
    <Actions>
      <Button
        size="icon"
        iconLeft="arrow-top"
        tooltip={onUp && "Sposta Su"}
        color="primary"
        tooltipPosition="left"
        radius={24}
        onClick={() => onUp && onUp()}
        disabled={!onUp}
      />
      <Button
        size="icon"
        iconLeft="arrow-bottom"
        color="primary"
        tooltip={onDown && "Sposta Giù"}
        tooltipPosition="right"
        radius={24}
        onClick={() => onDown && onDown()}
        disabled={!onDown}
      />
    </Actions>
  )
}

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  * {
    margin-right: 20px;
  }
`

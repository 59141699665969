import React, { useState, useEffect, useContext } from 'react'
import { Input } from '@duckma/react-ds'
import Skeleton from 'react-loading-skeleton'
import _ from 'lodash'

import { Validator } from '../utils/validators'
import { Formatter } from '../utils/formatters'
import { HtmlEditor } from './HtmlEditor'
import { usePrevious } from '../hooks/usePrevious'

type FieldProps = React.ComponentProps<typeof Input> & {
  validator?: Validator<string>
  formatter?: Formatter<string>
  fieldName?: string
}
export const ControlledField = (props: FieldProps) => {
  const {
    validator = (a: any, b: any) => null,
    formatter = _.identity,
    fieldName = props.name,
  } = props

  const { fields, onChange, loading, editingDisabled } = useContext(FormContext)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (!_.has(fields, fieldName)) {
      onChange(fieldName, '', validator('', _.mapValues(fields, 'value')))
    }
  }, [fields, fieldName, validator, onChange])

  useEffect(() => {
    // When fields change, rerun the validator. If status or error doesn't change, don't fire
    if (fields && _.get(fields, fieldName)) {
      const val = _.get(fields, fieldName)?.value
      if (typeof val === 'object') {
        console.warn(
          '[ControlledForm.ControlledField] Cannot run validator of object type. fieldName: ' +
            fieldName
        )
      }
      const error = validator(val as string, _.mapValues(fields, 'value'))
      if (error !== _.get(fields, fieldName).error) {
        onChange(fieldName, val as string, error)
      }
    }
  }, [fields, fieldName, validator, onChange])

  if (loading) {
    return <Skeleton />
  }

  return (
    <Input
      {...props}
      onBlur={() => setShowError(true)}
      disabled={editingDisabled || props.disabled}
      valid={!showError || _.get(fields, fieldName)?.error === null}
      errorText={_.get(fields, [fieldName, 'error'], '') || ''}
      value={formatter(_.get(fields, [fieldName, 'value'], '') as string)}
      onChange={(value) => {
        if (value !== _.get(fields, fieldName)?.value) {
          onChange(fieldName, value, validator(value, _.mapValues(fields, 'value')))
        }
      }}
    />
  )
}

type HtmlEditorProps = React.ComponentProps<typeof HtmlEditor> & {
  fieldName: string
  validator?: Validator
}
export const ControlledHtmlEditor = (props: HtmlEditorProps) => {
  const { validator = (a: any, b: any) => null, fieldName } = props

  const { fields, onChange, loading } = useContext(FormContext)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (!_.has(fields, fieldName)) {
      onChange(fieldName, '', validator('', _.mapValues(fields, 'value')))
    }
  }, [fields, fieldName, validator, onChange])

  useEffect(() => {
    // When fields change, rerun the validator. If status or error doesn't change, don't fire
    if (fields && fields[fieldName]) {
      const val = _.get(fields, fieldName)?.value
      if (typeof val === 'object') {
        console.warn(
          '[ControlledForm.ControlledHTMLEditor] Cannot run validator of object type. fieldName: ' +
            fieldName
        )
      }
      const error = validator(val as string, _.mapValues(fields, 'value'))
      if (error !== fields[fieldName].error) {
        onChange(fieldName, val as string, error)
      }
    }
  }, [fields, fieldName, validator, onChange])

  if (loading) {
    return <Skeleton />
  }

  return (
    <HtmlEditor
      {...props}
      onBlur={() => setShowError(true)}
      valid={!showError || fields[fieldName]?.error === null}
      errorText={_.get(fields, [fieldName, 'error'], '') || ''}
      value={String(_.get(fields, [fieldName, 'value'], ''))}
      onChange={(value) => {
        if (value !== fields[fieldName]?.value) {
          onChange(fieldName, value, validator(value, _.mapValues(fields, 'value')))
        }
      }}
    />
  )
}

type FieldState = {
  value: string
  error: string | null
}
type Fields = {
  [fieldName: string]: string | Fields
}
type NullableFields = {
  [fieldName: string]: string | Fields | null | undefined
}
type Context = {
  fields: {
    [fieldName: string]: FieldState
  }
  onChange: (fieldName: string, value: string, error: string | null) => void
  editingDisabled: boolean
  loading: boolean
}
const FormContext = React.createContext<Context>({
  onChange: (...rest) => {},
  editingDisabled: false,
  loading: false,
  fields: {},
})
export const ControlledForm: React.FC<{
  onChange?: (fields: Fields, valid: boolean) => void
  initialValues: null | NullableFields
  editingDisabled?: boolean
}> = ({ children, onChange: externalOnChange, initialValues = {}, editingDisabled = false }) => {
  const [values, setValues] = useState<{ [fieldName: string]: FieldState }>()
  const previousInitialValues = usePrevious(initialValues)

  useEffect(() => {
    if (previousInitialValues === null && initialValues != null) {
      setValues(objectToFlatStructure(initialValues))
    }
  }, [previousInitialValues, initialValues])

  const onChange = (fieldName: string, value: string, error: string | null) => {
    setValues((values) => ({ ...values, [fieldName]: { value, error } }))
  }

  useEffect(() => {
    if (externalOnChange) {
      externalOnChange(
        flatStructureToObject(_.mapValues(values, 'value')),
        _.map(values, 'error').every((err) => err === null)
      )
    }
  }, [externalOnChange, values])

  return (
    <FormContext.Provider
      value={{ onChange, editingDisabled, fields: values || {}, loading: initialValues == null }}
    >
      {children}
    </FormContext.Provider>
  )
}

const objectToFlatStructure: (
  obj: Record<string, string | object | null | undefined>,
  prefix?: string
) => Record<string, FieldState> = (obj: {}, prefix = '') =>
  _.reduce(
    obj,
    (acc, val, key) =>
      typeof val === 'object'
        ? {
            ...acc,
            ...objectToFlatStructure(val, prefix + key + '.'),
          }
        : { ...acc, [prefix + key]: { value: val || '', error: null } },
    {}
  )

const flatStructureToObject: (flat: Record<string, string>) => Record<string, string> = (obj: {}) =>
  _.reduce(
    obj,
    (acc, val, key) =>
      key.includes('.')
        ? {
            ...acc,
            [key.split('.')[0]]: {
              ..._.get(acc, key.split('.')[0], {}),
              ...flatStructureToObject({ [key.split('.')[1]]: val }),
            },
          }
        : { ...acc, [key]: val },
    {}
  )

import React from 'react'
import styled from 'styled-components'
import { Label, Description, useTheme } from '@duckma/react-ds'
import AsyncSelect from 'react-select/async'

import { InputProps } from '@duckma/react-ds/dist/components/Input'

export type SelectEntry = { value: string; label: string }
type Props = Omit<InputProps, 'value' | 'onChange'> & {
  value?: SelectEntry | SelectEntry[] | null
  onChange?: (value: SelectEntry) => void
  onMultipleChange?: (value: SelectEntry[]) => void
  getItemsPromise?: (search: string) => Promise<SelectEntry[]>
  emptyMessage?: string
  loadingMessage?: string
  multi?: boolean
  style?: {}
}

export const Select: React.FC<Props> = ({
  value,
  onChange,
  onMultipleChange,
  label = '',
  disabled,
  placeholder = 'Seleziona un elemento',
  emptyMessage = 'Nessun elemento trovato',
  loadingMessage = 'Caricamento...',
  getItemsPromise,
  valid = true,
  errorText,
  multi,
  style,
}) => {
  const theme = useTheme()

  return (
    <div style={{ width: '100%', ...style }}>
      {label && <Label text={label} style={{ marginBottom: '5px' }} color="black" />}
      <StyledSelect
        isDisabled={disabled}
        placeholder={placeholder}
        noOptionsMessage={() => emptyMessage}
        loadingMessage={() => loadingMessage}
        defaultOptions
        loadOptions={getItemsPromise}
        value={value}
        onChange={multi ? onMultipleChange : onChange}
        valid={valid}
        isMulti={multi}
        borderColor={theme['gray100']}
        errorBorderColor={theme['danger100']}
        styles={{
          control: (base: any) => ({
            ...base,
            height: '34px',
            minHeight: '34px',
            alignItems: 'flex-start',
            paddingTop: '1px',
          }),
          indicatorsContainer: (base: any) => ({
            ...base,
            marginTop: '-3px',
          }),
        }}
      />
      {!valid && (
        <Description
          text={errorText}
          color="danger100"
          style={{ marginTop: '5px', fontWeight: 400 }}
        />
      )}
    </div>
  )
}

const StyledSelect = styled(AsyncSelect)<{
  valid: boolean
  borderColor: string
  errorBorderColor: string
}>`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 13px;
  > div {
    border: 1px solid ${(props) => (props.valid ? props.borderColor : props.errorBorderColor)};
    :hover {
      border: 1px solid ${(props) => (props.valid ? props.borderColor : props.errorBorderColor)};
    }
    border-radius: 2px;
  }
`

import React, { useContext, useState, useRef } from 'react'
import styled from 'styled-components'
import ReactQuill from 'react-quill'
import htmlStrip from 'string-strip-html'
import 'react-quill/dist/quill.snow.css'
import { ThemeContext } from '@duckma/react-ds'
import { compose, map, replace } from 'ramda'
import { ColorName } from '@duckma/react-ds'

const capitalize = (s: string) => (s.length === 0 ? '' : s.charAt(0).toUpperCase() + s.slice(1))
const splitDashes = (s: string) => s.split(/_|-/)
const join = (s: string[]) => s.join(' ')
const dashesToText = compose(join, map(capitalize), splitDashes)

const modules = {
  toolbar: [['bold', 'italic'], ['link'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']],
}

const stripUnsupportedTags = compose(
  replace(/<p><br><\/p>(<ul>|<ol>)/gi, '$1'),
  replace(/:::(\/?)(a|br|b|i|strong|em|p|ul|ol|li)___(.*?):::/gi, '<$1$2$3>'),
  htmlStrip,
  replace(/<(\/?)(a|br|b|i|strong|em|p|ul|ol|li)(\s[^>]*)?>/gi, ':::$1$2___$3:::')
)

type Props = {
  name: string
  value?: string
  valid?: boolean
  label?: string
  description?: string
  placeholder?: string
  outlineColor?: ColorName
  errorText?: string
  onChange?: (value: string) => void
  onBlur?: () => void
  style?: {}
}
export const HtmlEditor = ({
  name,
  value,
  label = dashesToText(name),
  placeholder = label,
  outlineColor = 'primary100',
  description,
  valid,
  errorText,
  onChange,
  onBlur = () => {},
  style,
}: Props) => {
  const theme = useContext(ThemeContext)
  const editorRef = useRef<ReactQuill>(null)
  const [isFocused, setFocus] = useState(false)

  return (
    <Container style={style}>
      {label !== '' && (
        <Label htmlFor={name} color={theme['black']}>
          {label}
        </Label>
      )}
      <StyledEditor
        ref={editorRef}
        backgroundColor={theme['white']}
        borderColor={theme[isFocused ? outlineColor : 'gray100']}
        errorColor={valid ? undefined : theme['danger100']}
        theme="snow"
        {...{ value, placeholder }}
        onChange={(v) => compose((x) => onChange && onChange(x), stripUnsupportedTags)(v)}
        onFocus={() => setFocus(true)}
        onBlur={compose(() => {
          setFocus(false)
        }, onBlur)}
        modules={modules}
      />
      {description && <Description color={theme['gray100']}>{description}</Description>}
      {!valid && <Description color={theme['danger100']}>{errorText}</Description>}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
`
type StyledProps = {
  borderColor: string
  backgroundColor: string
  errorColor?: string
}
const StyledEditor = styled(ReactQuill)<StyledProps>`
  width: 100%;
  margin: 0;
  padding: 0;
  .ql-container {
    border: none;
  }
  .ql-editor {
    background-color: ${(props) => props.backgroundColor};
  }
  .ql-toolbar {
    border-left: none;
    border-right: none;
    border-top: none;
  }
  border: 1px solid ${(props) => props.errorColor || props.borderColor};
  border-radius: 2px;
`

const Label = styled.label<{ color: string }>`
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: ${(props) => props.color};
  margin: 0;
  padding: 0;
  margin-bottom: 5px;
  display: inline-block;
`

const Description = styled.span<{ color: string }>`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.color};
  margin: 0;
  padding: 0;
  margin-top: 5px;
  display: inline-block;
`

import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { SectionTitle } from '@duckma/react-ds'

import { MinimalUser } from '../../../data/models'
import { api } from '../../../data/api'
import { Select } from '../../../components/Select'
import { SessionContext } from '../../../components/MainLayout'

type Props = {
  initialData: { seller: MinimalUser | null }
  onChange: (data: { seller?: string | null }) => void
}

export const AssociationSection: React.FC<Props> = ({ initialData, onChange }) => {
  const { user: loggedInUser } = useContext(SessionContext)
  const [users, setUsers] = useState<{
    seller: { value: string; label: string } | null
  }>({ seller: null })

  useEffect(() => {
    setUsers({
      seller: initialData.seller
        ? {
            value: initialData.seller.id,
            label: `${initialData.seller.first_name} ${initialData.seller.last_name}`,
          }
        : loggedInUser?.role === 'seller'
        ? {
            value: loggedInUser.id,
            label: `${loggedInUser.first_name} ${loggedInUser.last_name}`,
          }
        : null,
    })
  }, [initialData, loggedInUser])

  useEffect(() => {
    if (loggedInUser?.role === 'seller') {
      onChange({ seller: loggedInUser.id })
    }
  }, [onChange, loggedInUser])

  useEffect(() => {
    if (users.seller) {
      onChange({ seller: users.seller?.value || null })
    }
  }, [onChange, users])

  return (
    <>
      <SectionTitle text="Associa" />
      <Container>
        <StyledSelect
          name="seller_association"
          placeholder="Seleziona l'agenzia"
          label="Associa a agenzia"
          disabled={loggedInUser?.role !== 'admin'}
          getItemsPromise={(search: string) =>
            api
              .getUsersBySearch({
                search_text: search,
                ignore_ids: users.seller ? [users.seller.value] : [],
                role: 'seller',
              })
              .then(({ items }) =>
                items.map(({ id, first_name, last_name }) => ({
                  value: id,
                  label: `${first_name} ${last_name}`,
                }))
              )
          }
          value={users.seller}
          onChange={(seller) => setUsers({ seller })}
        />
        <div />
      </Container>
    </>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10%;
  padding-top: 30px;
  padding-bottom: 30px;
`

const StyledSelect = styled(Select)`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 13px;
  width: 50%;
  > div {
    border: 0.5px solid #828894;
    border-radius: 2px;
  }
`

import { User, Role, Media, MinimalUser, Prospect, Item } from './models'
import { storage } from './storage'
import _ from 'lodash'

class API {
  private request = async <T>(
    method: string,
    path: string,
    body?: {},
    params?: { [key: string]: string | string[] },
    useToken: boolean = true
  ) => {
    let uri = process.env.REACT_APP_BASE_URL + path
    if (_.keys(params).length > 0) {
      uri +=
        '?' +
        _.map(params, (value, key) =>
          Array.isArray(value) ? value.map((v) => `${key}=${v}`).join('&') : `${key}=${value}`
        ).join('&')
    }
    return fetch(uri, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: this.getHeaders(useToken),
    }).then((res) => this.handleResponse<T>(res))
  }

  private handleResponse = <T>(response: Response) => {
    if (response.status !== 200) {
      console.error(`Error while sending request. Code: ${response.status}`)
    }
    if (response.status === 401) {
      this.clearSession()
      window.location.reload()
    }
    return response.json().then((json: { data: T | null; error: null | { code: string } }) => {
      if (response.status !== 200) {
        throw new Error(json.error!.code || 'invalid_server_response')
      }
      return json.data!
    })
  }

  private getHeaders = (useToken: boolean) => {
    const token = storage.getToken()
    return {
      ...(useToken && token ? { Authorization: `Bearer ${token}` } : undefined),
      Platform: 'console',
      'User-Language': navigator?.language,
      'Content-Type': 'application/json',
    }
  }

  private get = async <T>(
    path: string,
    params: { [key: string]: string | string[] },
    useToken: boolean = true
  ) => this.request<T>('GET', path, undefined, params, useToken)

  private delete = async <T>(
    path: string,
    params: { [key: string]: string },
    useToken: boolean = true
  ) => this.request<T>('DELETE', path, undefined, params, useToken)

  private post = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('POST', path, body, undefined, useToken)

  private put = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('PUT', path, body, undefined, useToken)

  private patch = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('PATCH', path, body, undefined, useToken)

  private cleanParams = (params: {
    [key: string]: string | number | null | undefined | string[]
  }) =>
    _(params)
      .pickBy((value) => value !== null && value !== undefined && value !== '')
      .mapValues((param) => {
        if (Array.isArray(param)) {
          return param.map((p) => String(p))
        }
        return String(param)
      })
      .value()

  private persistSession = (res: LoginResponse) => {
    storage.saveToken(res.token)
    storage.saveUser(res.user)
    return res
  }

  public clearSession = () => {
    storage.deleteToken()
    storage.deleteUser()
  }

  public login = ({ email, password }: { email: string; password: string }) =>
    this.post<LoginResponse>('/auth/login', { email, password, provider: 'email' }, false)
      .then((data) => {
        if (data.user.role !== 'user') {
          return data
        }
        throw new Error('insufficient_role')
      })
      .then(this.persistSession)

  public recoverPassword = ({ email }: { email: string }) =>
    this.post<{}>('/auth/otp/request', { email }, false)

  public loginOTP = (body: { email: string; otp: string }) =>
    this.post<LoginResponse>('/auth/otp/login', body, false).then(this.persistSession)

  public updatePassword = ({
    password,
    session,
  }: {
    password: string
    session?: LoginResponse
  }) => {
    if (session) {
      // save this one in localStorage before requesting
      this.persistSession(session)
    }
    return this.put<User>('/me/password', { password }).catch((err) => {
      if (session) {
        this.clearSession()
      }
      throw err
    })
  }

  public getMe = () => this.get<User>('/me', {})

  public updateMe = (body: Partial<UpdateUser>) => this.patch<User>('/me', body)

  public getUsers = (params: ListParams) =>
    this.get<PaginatedResponse<User>>('/users', this.cleanParams(params))

  public getUser = (params: { id: string }) => this.get<User>(`/users/${params.id}`, {})

  public createUser = (body: CreateUser) =>
    this.post<User>('/users', { ...body, provider: 'email' })

  public updateUser = ({ id, body }: { id: string; body: UpdateUser }) =>
    this.patch<User>(`/users/${id}`, body)

  public deleteUser = (params: { id: string }) => this.delete<User>(`/users/${params.id}`, {})
  public blockUser = (params: { id: string }) => this.post<void>(`/users/${params.id}/block`, {})
  public unblockUser = (params: { id: string }) =>
    this.post<void>(`/users/${params.id}/unblock`, {})
  public activateUser = ({ id, seller }: { id: string; seller: string }) =>
    this.post<void>(`/users/${id}/activate`, { seller })

  public getUsersBySearch = ({
    search_text,
    ignore_ids = [],
    role,
    seller,
  }: {
    search_text: string
    ignore_ids: string[]
    role?: Role
    seller?: string
  }) =>
    this.get<PaginatedResponse<MinimalUser>>(
      '/users',
      this.cleanParams({
        search_text,
        page: 0,
        page_size: 10,
        format: 'minimal',
        ignore_ids,
        roles: role ? [role] : null,
        seller,
      })
    )

  public getMedias = (params: ListParams) =>
    this.get<PaginatedResponse<Media>>('/media', this.cleanParams(params))

  public getMedia = (params: { id: string }) => this.get<Media>(`/media/${params.id}`, {})

  public createMedia = (body: CreateMedia) => this.post<Media>('/media', body)

  public updateMedia = ({ id, body }: { id: string; body: UpdateMedia }) =>
    this.patch<Media>(`/media/${id}`, body)

  public deleteMedia = (params: { id: string }) => this.delete<Media>(`/media/${params.id}`, {})

  public getItems = (params: ListParams) =>
    this.get<PaginatedResponse<Item>>('/items', this.cleanParams(params))

  public getItem = (params: { id: string }) => this.get<Item>(`/items/${params.id}`, {})

  public createItem = (body: CreateItem) => this.post<Item>('/items', { ...body, requires: [] })

  public updateItem = ({ id, body }: { id: string; body: UpdateComponent }) =>
    this.patch<Item>(`/items/${id}`, body)

  public orderItem = ({ id, toPosition }: { id: string; toPosition: number }) =>
    this.post<Item>(`/items/${id}/move`, { toPosition })

  public deleteItem = (params: { id: string }) => this.delete<Item>(`/items/${params.id}`, {})

  public getItemsByTitle = (title: string, hidden = 'false') =>
    this.get<PaginatedResponse<Pick<Item, 'id' | 'title'>>>(
      '/items',
      this.cleanParams({ search_text: title, page: 0, page_size: 10, hidden, format: 'minimal' })
    )

  public getProspects = (params: ListParams) =>
    this.get<PaginatedResponse<Prospect>>('/prospects', this.cleanParams(params))

  public getProspect = (params: { id: string }) => this.get<Prospect>(`/prospects/${params.id}`, {})

  public sendOffer = ({
    id,
    body,
  }: {
    id: string
    body: { offer_comment: string; attachments?: string[] }
  }) => this.post<{}>(`/prospects/${id}/offer`, body)

  public reopenOffer = ({ id }: { id: string }) =>
    this.patch<{}>(`/prospects/${id}`, { status: 'requested' })

  public deleteProspect = (params: { id: string }) =>
    this.delete<Prospect>(`/prospects/${params.id}`, {})

  public getProspectsByTitle = (title: string, status = 'published') =>
    this.get<PaginatedResponse<Pick<Prospect, 'id' | 'title'>>>(
      '/prospects',
      this.cleanParams({ title, page: 0, page_size: 10, status, format: 'minimal' })
    )

  public createProspect = (body: {}) => {
    return this.post<Prospect>(`/prospects`, body)
  }

  public getPWAVersion = () => this.get<{ version: string }>(`/system/pwa_version`, {})
  public updatePWAVersion = () => this.patch<{}>(`/system/pwa_version`, {})
}

export type LoginResponse = { token: string; user: User }

export type PaginatedResponse<T> = {
  items: T[]
  pagination: { current_page: number; total_pages: number; page_size: number }
}

export type ListParams = {
  search_text?: string
  order_by?: string
  order_direction?: 'asc' | 'desc'
  page?: number
  page_size?: number
  [key: string]: string | number | undefined | string[]
}

export type CreateUser = Pick<
  User,
  | 'first_name'
  | 'last_name'
  | 'email'
  | 'role'
  | 'seller'
  | 'business_name'
  | 'phone_number'
  | 'type'
  | 'tax_code_or_vat_code'
> & {
  password: string
}

export type UpdateUser = Pick<
  User,
  | 'first_name'
  | 'last_name'
  | 'business_name'
  | 'multiplier_percent'
  | 'phone_number'
  | 'type'
  | 'tax_code_or_vat_code'
> & {
  seller: string
}

export type CreateItem = Pick<
  Item,
  | 'title'
  | 'order'
  | 'image'
  | 'short_description'
  | 'details_url'
  | 'mandatory'
  | 'points'
  | 'price'
  | 'price_model'
  | 'price_recurrence'
  | 'doc_tags'
> & { requires: string[] }

export type UpdateComponent = CreateItem

export type CreateMedia = Pick<Media, 'title'> & { base64: string; type: 'image' | 'doc' }
export type UpdateMedia = Omit<CreateMedia, 'base64' | 'image'>

export type ValidateFlow = { base64: string }
export type ValidateResult = { valid: boolean; well_formed: boolean }
export type UpdateFlow = { base64: string }
export type UpdateModel = { base64: string }

export const api = new API()

export type Role = 'user' | 'seller' | 'admin' | 'super_admin'

export interface User {
  id: string
  email: string
  role: Role
  first_name: string
  last_name: string
  created_at: string
  last_login?: string
  seller: User | null
  status: 'active' | 'blocked'
  phone_number?: string
  business_name?: string
  type?: 'private' | 'company'
  tax_code_or_vat_code?: string
  multiplier_percent?: number
}
export type MinimalUser = Pick<User, 'id' | 'email' | 'first_name' | 'last_name'>
export interface Media {
  id: string
  title: string
  type: 'image' | 'doc'
  url: string
  thumbnail_url?: string
  size_bytes: number
  created_at: string
}

export enum Category {
  COMPONENTS = 'components',
  GRAPHICS = 'graphics',
  PLATFORMS = 'platforms',
  SERVICES = 'services',
}
export enum PriceRecurrence {
  ONESHOT = 'oneshot',
  MONTHLY = 'monthly',
}

export enum PriceModel {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}
export enum Languages {
  IT = 'it',
  EN = 'en',
}
export type Localized<T = string> = {
  [key in Languages]: T
}

export class Points {
  define?: number;
  build?: number;
  grow?: number;
}

export interface Item {
  id: string
  title: string
  order: number
  image: Media
  points: Points
  price: number
  price_recurrence: PriceRecurrence
  price_model: PriceModel
  short_description: string
  details_url: string
  mandatory: boolean
  requires: Pick<Item, 'id' | 'title'>[]
  doc_tags?: string[]
  category: Category
  created_at: string
}
export type MinimalItem = Pick<Item, 'id' | 'title'>

export type ProspectStatus = 'saved' | 'requested' | 'accepted' | 'declined'

export type ProspectItem = Pick<
  Item,
  'id' | 'title' | 'points' | 'price' | 'price_recurrence' | 'price_model' | 'category'
> & {
  customization: boolean
  customization_notes?: string
  customization_rating?: number
}

export interface Prospect {
  id: string
  title: string
  status: ProspectStatus
  short_description: string
  created_at: string
  items: ProspectItem[]
  user: User
  attachments: Media[]
  offer_comment: string
  offer_attachments: Media[]
  offer_file_url?: string
  customer_name?: string
  company_name?: string
  financial_plan?: { percentage_upfront: number; duration_months: number }
  multiplier_percent: number
}

import React, { useContext } from 'react'
import styled from 'styled-components'
import { ThemeContext, Button } from '@duckma/react-ds'
import { humanReadableBytes } from '../utils/helpers'

type ImageProps = {
  url: string
  width?: string
  height?: string
  title?: string
  sizeBytes?: number
  onEdit?: () => void
  onDelete?: () => void
  selected?: boolean
  onClick?: () => void
  style?: {}
}
export const Image = ({
  url,
  width = '100%',
  height = 'auto',
  title,
  sizeBytes,
  onEdit,
  onDelete,
  selected = false,
  onClick,
  style,
}: ImageProps) => {
  const theme = useContext(ThemeContext)

  return (
    <ImageContainer
      {...{ style, width, height, selected }}
      borderColor={theme['primary100']}
      onClick={onClick}
    >
      <Overlay width={width} height={height}>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '75%', marginBottom: '5px' }}
        >
          {title && <Title color={theme['white']}>{title}</Title>}
          {sizeBytes && <FileSize color={theme['white']}>{humanReadableBytes(sizeBytes)}</FileSize>}
        </div>
        <div
          style={{
            display: 'flex',
            height: '50px',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '25%',
          }}
        >
          {onEdit ? (
            <Button
              size="icon"
              radius={24}
              iconLeft="pen-alt"
              onClick={onEdit}
              style={{ backgroundColor: theme['gray100'] }}
            />
          ) : (
            <div style={{ width: '24px', height: '24px' }} />
          )}
          {onDelete && (
            <Button size="icon" radius={24} iconLeft="trash" color="danger" onClick={onDelete} />
          )}
        </div>
      </Overlay>
      <BackgroundImage src={url} width={width} height={height} />
    </ImageContainer>
  )
}

const ImageContainer = styled.div<{
  width: string
  height: string
  selected: boolean
  borderColor: string
}>`
  border-radius: 12px;
  box-shadow: ${(props) =>
    props.selected ? '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)' : 'none'};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background-color: transparent;
  transition: all 0.3s ease;
  border: 2px solid ${(props) => (props.selected ? props.borderColor : 'transparent')};
`

const BackgroundImage = styled.img<{ width: string; height: string }>`
  border-radius: 10px;
  position: relative;
  width: 100%;
  left: -100%;
  object-fit: cover;
  object-position: 50% 50%;
  transition: all 0.3s ease;
  box-sizing: border-box;
`

const Overlay = styled.div<{ width: string; height: string }>`
  border-radius: 10px;
  box-sizing: border-box;
  min-width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  padding-left: 17px;
  padding-right: 17px;
  z-index: 1;
  background: linear-gradient(transparent 25%, rgba(0, 0, 0, 0.5));

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  transition: all 0.3s ease;
`

const FileSize = styled.div<{ color: string }>`
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  color: ${(props) => props.color};
  white-space: nowrap;
  width: 80%;
`

const Title = styled.div<{ color: string }>`
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  line-height: 24px;
  font-weight: 800;
  color: ${(props) => props.color};

  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

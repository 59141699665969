import React, { useState } from 'react'
import styled from 'styled-components'
import { Input, Button } from '@duckma/react-ds'

type Props = {
  buttonText?: string
  onSearch: (text: string) => void
  style?: {}
}

export const SearchField: React.FC<Props> = ({ buttonText = 'Cerca', onSearch, style }) => {
  const [value, setValue] = useState('')

  return (
    <Container
      style={style}
      onSubmit={(e) => {
        e.preventDefault()
        onSearch(value)
      }}
    >
      <StyledInput
        type="text"
        name="cerca"
        label=""
        placeholder="Inserisci il testo da cercare"
        value={value}
        onChange={setValue}
      />
      <Button text={buttonText} onClick={() => onSearch(value)} style={buttonStyles} />
    </Container>
  )
}

const Container = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 35px;
  min-width: 30vw;
`

const StyledInput = styled(Input)`
  display: block;
  flex-grow: 1;
  padding-right: 0;
  > input {
    padding-top: 7px;
    padding-bottom: 6px;
  }
`

const buttonStyles = {
  display: 'block',
  flexGrow: 0,
  flexBasis: '25%',
  height: '36px',
  padding: 0,
  borderRadius: '0 4px 4px 0',
}

import { useState, useCallback, useEffect } from 'react'
import { useRequest } from './useRequest'

export const useQueue = <T>(initial: T[]) => {
  const [queue, setQueue] = useState<T[]>(initial)

  const enqueue = useCallback((element: T) => {
    setQueue((queue) => [...queue, element])
  }, [])

  const clear = useCallback(() => {
    setQueue([])
  }, [])

  const dequeue = useCallback(() => {
    if (queue.length === 0) {
      return null
    }
    const head = queue[0]
    setQueue((queue) => queue.slice(1))
    return head
  }, [queue])

  return [queue, { enqueue, dequeue, clear }] as [
    T[],
    { enqueue: (newElement: T) => void; dequeue: () => T | null; clear: () => void }
  ]
}

export const useNetworkQueue = <P, T>(
  apiCall: (data: P) => Promise<T>,
  onComplete: (result: T) => void = () => {}
) => {
  const [request, { status, data }, reset] = useRequest(apiCall)
  const [queue, { enqueue, dequeue, clear }] = useQueue<P>([])

  const [lock, setLock] = useState(false)
  const [erroredItem, setErroredItem] = useState<P | null>(null)

  const enqueueAndClear = useCallback(
    (item) => {
      enqueue(item)
      setErroredItem(null)
    },
    [enqueue]
  )

  useEffect(() => {
    if (status === 'success' && data && lock) {
      onComplete(data)
      setLock(false)
      dequeue()
      reset()
    }
    if (status === 'error' && lock) {
      setLock(false)
      setErroredItem(queue[0])
      clear()
      reset()
    }
    if (queue.length > 0 && status === 'idle' && !lock) {
      setLock(true)
      request(queue[0])
    }
  }, [queue, status, lock, setLock, request, dequeue, clear, data, onComplete, reset])

  return [queue, { enqueue: enqueueAndClear }, erroredItem] as [
    P[],
    { enqueue: (newElement: P) => void },
    P | null
  ]
}

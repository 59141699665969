import React, { useContext, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Sidebar, ThemeContext, Button } from '@duckma/react-ds'
import _ from 'lodash'

import Logo from '../images/logo-light.png'
import { storage } from '../data/storage'
import { useLocation, useHistory } from 'react-router-dom'
import { api } from '../data/api'
import { Header } from './Header'
import { User, Role } from '../data/models'
import { version } from '../../package.json'
import { SidebarStructure } from '@duckma/react-ds/dist/components/Sidebar'

export const SessionContext = React.createContext<{
  user?: User
  mustReload: () => void
  flagDirty: () => void
  resetDirty: () => void
}>({ mustReload: () => {}, flagDirty: () => {}, resetDirty: () => {} })

export const MainLayout: React.FC<{
  title: string
  backLink?: { to: string; text: string }
  headerButton?: { text: string; to: string; roles?: Role[] }
}> = ({ children, title, backLink, headerButton }) => {
  const theme = useContext(ThemeContext)
  const location = useLocation()
  const history = useHistory()
  // If a form is dirty (with unsaved user work on it) the page sets dirty to true.
  // If the user then tries to go back, a popup is shown
  const [dirty, setDirty] = useState(false)

  const [user, setUser] = useState(storage.getUser())
  const mustReload = useCallback(() => setUser(storage.getUser()), [])
  const flagDirty = useCallback(() => {
    window.onbeforeunload = () => ''
    setDirty(true)
  }, [])
  const resetDirty = useCallback(() => {
    window.onbeforeunload = () => null
    setDirty(false)
  }, [])

  return (
    <Grid>
      <SidebarContainer>
        <Sidebar
          structure={[
            {
              name: 'users',
              label: 'Utenti',
              icon: 'user-alt',
            },
            {
              name: 'prospects',
              label: 'Offerte',
              icon: 'folder',
              children: [
                { name: 'prospects/saved', label: 'Salvate', icon: 'business' },
                { name: 'prospects/requested', label: 'Richieste', icon: 'question' },
                { name: 'prospects/accepted', label: 'Accettate', icon: 'ok' },
              ],
            },
            ...(user.role === 'admin'
              ? ([
                  {
                    name: 'items',
                    label: 'Voci',
                    icon: 'folder',
                  },
                  {
                    name: 'media',
                    label: 'Media',
                    icon: 'img',
                  },
                  {
                    name: 'system',
                    label: 'Configurazione',
                    icon: 'wrench',
                  },
                ] as SidebarStructure)
              : []),
          ]}
          pinned={[
            {
              name: 'me',
              label: user.first_name + ' ' + user.last_name,
              icon: 'admin',
              showArrow: false,
            },
            {
              name: 'logout',
              label: 'Logout',
              showArrow: false,
            },
          ]}
          logo={Logo}
          logoStyles={{ width: '100%' }}
          selected={_(location.pathname).split('/').tail().join('/')}
          color="primary100"
          selectedColor="primary50"
          version={version}
          onSelect={(name) => {
            switch (name) {
              case 'logout':
                api.clearSession()
                history.push('/login')
                break
              default:
                if (dirty) {
                  window.location.href = `/${name}`
                } else {
                  history.push(`/${name}`)
                }
                break
            }
          }}
        />
      </SidebarContainer>
      <HeaderContainer color={theme['gray20']}>
        <Header
          title={title}
          backLink={backLink}
          dirty={dirty}
          button={
            headerButton &&
            (headerButton.roles === undefined || headerButton.roles.includes(user.role)) ? (
              <Button
                text={headerButton.text}
                onClick={() => {
                  if (dirty) {
                    return (window.location.href = headerButton.to)
                  }
                  history.push(headerButton.to)
                }}
                size="large"
                radius={4}
              />
            ) : undefined
          }
        ></Header>
      </HeaderContainer>
      <SessionContext.Provider value={{ mustReload, flagDirty, resetDirty, user }}>
        <ContentContainer color={theme['gray20']}>{children}</ContentContainer>
      </SessionContext.Provider>
    </Grid>
  )
}

const Grid = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-template-rows: 160px 1fr;
`

const SidebarContainer = styled.div`
  width: 260px;
  max-height: 100vh;
  height: 100%;
  grid-area: 1 / 1 / 3 / 2;
  position: fixed;
  top: 0;
  left: 0;
`

const HeaderContainer = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 100%;
  grid-area: 1 / 2 / 2 / 3;
`

const ContentContainer = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 100%;
  grid-area: 2 / 2 / 3 / 3;
`
